import { AnyAction } from 'redux'
import { ActionTypes } from '../action/action'
import { EntityClass, Workspace } from '../types'
import { newDefaultEntity } from '../../entity/schema'
import { Entity } from '../../entity/types'

export const workspaceInitState: Workspace = {
    loading: false,
    currentEntityClass: EntityClass.ORDER,
    workspaces: {
        [EntityClass.ORDER]: {
            entityClass: EntityClass.ORDER,
            dataFilter: {
                filterCompletedRecords: false
            }
        },
        [EntityClass.ORDER_MATERIAL]: {
            entityClass: EntityClass.ORDER_MATERIAL,
            dataFilter: {
                filterCompletedRecords: false
            }
        },
        [EntityClass.EMPLOYEE_APPLICATION]: {
            entityClass: EntityClass.EMPLOYEE_APPLICATION,
            dataFilter: {
                filterCompletedRecords: false
            }
        },
        [EntityClass.NOTIFICATION]: {
            entityClass: EntityClass.NOTIFICATION
        }
    }
}

export function workspaceReducer (state: Workspace = workspaceInitState, action: AnyAction): Workspace {
    const currentEntityClass = state.currentEntityClass
    const currentWorkspace = state.workspaces[currentEntityClass]
    switch (action.type) {
    case ActionTypes.WORKSPACE_SELECT_ENTITY_CLASS: {
        const entityClass = action.target as EntityClass
        if (currentEntityClass === entityClass) {
            return state
        }

        return {
            ...state,
            currentEntityClass: action.target
        }
    }
    case ActionTypes.SELECT_ENTITY: {
        const entity = action.target as Entity
        return {
            ...state,
            workspaces: {
                ...state.workspaces,
                [currentEntityClass]: {
                    ...state.workspaces[currentEntityClass],
                    selectedEntity: {
                        entity: entity,
                        meta: {
                            entityClass: currentEntityClass,
                            entityId: entity.id,
                            isNew: false
                        }
                    }
                }
            }
        }
    }
    case ActionTypes.UNSELECT_ENTITY: {
        return {
            ...state,
            workspaces: {
                ...state.workspaces,
                [currentEntityClass]: {
                    ...currentWorkspace,
                    selectedEntity: undefined
                }
            }
        }
    }
    case ActionTypes.WORKSPACE_CHANGE_DATA_FILTER: {
        if ('dataFilter' in currentWorkspace) {
            return {
                ...state,
                workspaces: {
                    ...state.workspaces,
                    [currentEntityClass]: {
                        ...currentWorkspace,
                        dataFilter: Object.assign({}, currentWorkspace.dataFilter, action.changes)
                    }
                }
            }
        } else {
            return state
        }
    }
    case ActionTypes.CREATE_ENTITY: {
        // todo consider to remove action.entityClass
        const entityClass = action.entityClass as EntityClass
        const newEntity = newDefaultEntity(entityClass)
        return {
            ...state,
            workspaces: {
                ...state.workspaces,
                [entityClass]: {
                    ...state.workspaces[entityClass],
                    selectedEntity: {
                        entity: newEntity,
                        meta: {
                            entityClass: entityClass,
                            entityId: -1,
                            isNew: true
                        }
                    }
                }
            }
        }
    }
    case ActionTypes.DELETE_ENTITY_SUCCEEDED: {
        const entityClass = action.entityClass as EntityClass
        const entityId = action.entityId as number
        const currentWorkspace = state.workspaces[entityClass]
        if ('selectedEntity' in currentWorkspace && currentWorkspace.selectedEntity?.meta.entityId === entityId) {
            return {
                ...state,
                workspaces: {
                    ...state.workspaces,
                    [entityClass]: {
                        ...state.workspaces[entityClass],
                        selectedEntity: undefined
                    }
                }
            }
        }
        break
    }
    case ActionTypes.AUTH_LOGOUT: {
        return { ...workspaceInitState }
    }
    case ActionTypes.WORKSPACE_START_LOAD: {
        return {
            ...state,
            loading: true
        }
    }
    case ActionTypes.WORKSPACE_FINISH_LOAD: {
        return {
            ...state,
            loading: false
        }
    }
    }

    return state
}

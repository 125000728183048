import React from 'react'

import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import Table from 'react-bootstrap/Table'

import { selectEntity, unselectEntity } from '../../store/action/select-entity'
import { AppState, EntityClass, TableEditorWorkspace } from '../../store/types'
import { ClassNameProps } from '../types'
import { Entity } from '../../entity/types'

import './TableBlock.css'
import { ColumnSpec } from '../../entity/schema'

interface TableBlockProps {
    entityClass: EntityClass
    data: Entity[]
    selectedEntityId?: number
}

interface OwnTableBlockProps {
    spec: ColumnSpec[]
}

interface TableBlockActions {
    selectEntity: (entity: Entity) => void
    unselectEntity: () => void
}

export class TableBlock extends React.Component<TableBlockProps & OwnTableBlockProps & TableBlockActions & ClassNameProps, {}> {
    constructor (props: TableBlockProps & OwnTableBlockProps & TableBlockActions & ClassNameProps) {
        super(props)

        this.selectRow = this.selectRow.bind(this)
    }

    render () {
        return <div className={'tb-table'}>
            <Table bordered>
                <thead>
                    <tr>
                        { this.props.spec.map((column: ColumnSpec) => <th key={column.name}>{column.title}</th>) }
                    </tr>
                </thead>
                <tbody>
                    { Object.values(this.props.data).map((entity: Entity, index: number) =>
                        <tr key={index}
                            onClick={this.selectRow}
                            className={this.props.selectedEntityId === entity.id ? 'tb-row-selected' : ''}
                        >
                            { this.props.spec.map((column: ColumnSpec) => <td key={column.name}>{this.formatCellValue(column, entity[column.name])}</td>) }
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    }

    selectRow (event: React.MouseEvent<HTMLTableRowElement>): void {
        event.preventDefault()
        const row = event.currentTarget
        const selectedEntity = this.props.data[row.rowIndex - 1]
        if (this.props.selectedEntityId === selectedEntity.id) {
            this.props.unselectEntity()
        } else {
            this.props.selectEntity(selectedEntity)
        }
    }

    formatCellValue (column: ColumnSpec, value: any): any {
        if (column.format) {
            return column.format(value)
        } else {
            return value
        }
    }
}

const mapStateToProps = (state: AppState, ownProps: OwnTableBlockProps & ClassNameProps): TableBlockProps & OwnTableBlockProps & ClassNameProps => {
    const currentEntityClass = state.workspace.currentEntityClass
    const currentWorkspace = state.workspace.workspaces[currentEntityClass] as TableEditorWorkspace
    const meta = currentWorkspace.selectedEntity?.meta
    let selectedEntityId: number | undefined
    if (meta && !meta.isNew) {
        selectedEntityId = meta.entityId
    }
    return {
        className: ownProps.className,
        spec: ownProps.spec,

        entityClass: currentEntityClass,
        data: state.data.dataset[currentEntityClass],
        selectedEntityId: selectedEntityId
    }
}

const mapDispatchToProps = (dispatch: Dispatch): TableBlockActions => {
    return {
        selectEntity: (entity: Entity) => dispatch(selectEntity(entity)),
        unselectEntity: () => dispatch(unselectEntity())
    }
}

export const ConnectedTableBlock = connect(mapStateToProps, mapDispatchToProps)(TableBlock)

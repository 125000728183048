import React from 'react'
import { Modal } from 'react-bootstrap'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import Switch from 'react-bootstrap/Switch'

import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { AppState, DataFilter, EntityClass, EntityInfo } from '../../store/types'
import { fetchDatasetPage } from '../../store/action/fetch-data'
import { createEntity, deleteEntity } from '../../store/action/entity-actions'
import { EntityMeta } from '../../entity/types'

import './ControlBlock.css'
import { changeDataFilter } from '../../store/action/select-entity'
import { GetEntityParams } from '../../api/get-entity'

interface ControlBlockProps {
    entityClass: EntityClass
    selectedEntity?: EntityInfo
    dataFilter: DataFilter

    allowRefreshAction?: boolean
    allowCreateAction?: boolean
    allowDeleteAction?: boolean
}

interface ControlBlockActions {
    refreshTable: (entityClass: EntityClass, params: GetEntityParams) => void
    createEntity: (entityClass: EntityClass) => void
    deleteSelectedEntity: (entityMeta: EntityMeta) => void
    changeFilterCompletedRecords: (value: boolean) => void
}

interface ControlBlockState {
    showDeleteModal: boolean
}

export class ControlBlock extends React.Component<ControlBlockProps & ControlBlockActions, ControlBlockState> {
    constructor (props: ControlBlockProps & ControlBlockActions) {
        super(props)

        this.state = {
            showDeleteModal: false
        }

        this.showDeleteModal = this.showDeleteModal.bind(this)
        this.hideDeleteModal = this.hideDeleteModal.bind(this)
        this.deleteEntity = this.deleteEntity.bind(this)
    }

    render () {
        return <div className={'cb-button-group-wrapper'}>
            <ButtonGroup className={'cb-button-group'}>
                <Button
                    onClick={() => this.props.refreshTable(this.props.entityClass, { filterCompleted: this.props.dataFilter.filterCompletedRecords })}
                    disabled={!this.props.allowRefreshAction}
                >
                    Обновить
                </Button>
            </ButtonGroup>
            <ButtonGroup className={'cb-button-group'}>
                <Button
                    onClick={() => this.props.createEntity(this.props.entityClass)}
                    disabled={!this.props.allowCreateAction}
                >
                    Создать
                </Button>
                <Button
                    onClick={this.showDeleteModal}
                    disabled={!this.props.allowDeleteAction || !this.props.selectedEntity}
                >
                    Удалить
                </Button>
            </ButtonGroup>

            <Switch id={'filter-toggle'} className={'cb-switch'} inline>
                <Switch.Input checked={this.props.dataFilter.filterCompletedRecords} onChange={() => this.props.changeFilterCompletedRecords(!this.props.dataFilter.filterCompletedRecords)}/>
                <Switch.Label className={'cb-switch-label'}>Скрыть неактуальные записи</Switch.Label>
            </Switch>

            <Modal show={this.state.showDeleteModal} onHide={this.hideDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Удаление</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Вы действительно хотите удалить запись из таблицы?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.hideDeleteModal}>Отмена</Button>
                    <Button variant="primary" onClick={this.deleteEntity}>Удалить</Button>
                </Modal.Footer>
            </Modal>

        </div>
    }

    showDeleteModal () {
        this.setState({ showDeleteModal: true })
    }

    hideDeleteModal () {
        this.setState({ showDeleteModal: false })
    }

    deleteEntity () {
        if (this.props.selectedEntity) {
            this.props.deleteSelectedEntity(this.props.selectedEntity.meta)
        }
        this.hideDeleteModal()
    }
}

const mapStateToProps = (state: AppState): ControlBlockProps => {
    const permissions = state.auth.userInfo.permissions
    const entityClass = state.workspace.currentEntityClass
    const currentWorkspace = state.workspace.workspaces[entityClass]

    let entity: EntityInfo | undefined
    let filter: DataFilter = { filterCompletedRecords: false }
    if ('dataFilter' in currentWorkspace) {
        entity = currentWorkspace.selectedEntity
        filter = currentWorkspace.dataFilter
    }

    return {
        entityClass: entityClass,
        selectedEntity: entity,
        dataFilter: filter,

        allowRefreshAction: permissions.includes(`${entityClass}r`),
        allowCreateAction: permissions.includes(`${entityClass}c`),
        allowDeleteAction: permissions.includes(`${entityClass}d`)
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ControlBlockActions => {
    return {
        refreshTable: (entityClass: EntityClass, params: GetEntityParams) => dispatch(fetchDatasetPage(entityClass, params)),
        createEntity: (entityClass: EntityClass) => dispatch(createEntity(entityClass)),
        deleteSelectedEntity: (entityMeta: EntityMeta) => dispatch(deleteEntity(entityMeta.entityClass!!, entityMeta.entityId!!)),
        changeFilterCompletedRecords: (value: boolean) => dispatch(changeDataFilter({ filterCompletedRecords: value }))
    }
}

export const ConnectedControlBlock = connect(mapStateToProps, mapDispatchToProps)(ControlBlock)

import { ActionTypes } from './action'
import { EntityClass } from '../types'
import { Entity } from '../../entity/types'
import { GetEntityParams } from '../../api/get-entity'

export function fetchDatasetPage (entity: EntityClass, params: GetEntityParams) {
    return {
        type: ActionTypes.FETCH_DATASET_PAGE,
        entityClass: entity,
        params: params
    }
}

export function fetchDatasetPageSucceeded (entity: EntityClass, data: Entity[]) {
    return {
        type: ActionTypes.FETCH_DATASET_PAGE_SUCCEEDED,
        entityClass: entity,
        data: data
    }
}

export function fetchDatasetPageFailed () {
    return {
        type: ActionTypes.FETCH_DATASET_PAGE_FAILED
    }
}

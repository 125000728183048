import { ActionTypes } from './action'
import { User } from '../types'
import { ErrorReason } from '../../api/types'

export function loginRequest (username: string, password: string) {
    return {
        type: ActionTypes.AUTH_LOGIN_REQUEST,
        username: username,
        password: password
    }
}

export function loginRequestSucceeded (token: string, expirationTime: number, userInfo: User) {
    return {
        type: ActionTypes.AUTH_LOGIN_REQUEST_SUCCEEDED,
        token: token,
        expirationTime: expirationTime,
        user: userInfo
    }
}

export function loginRequestFailed (errReason: ErrorReason) {
    return {
        type: ActionTypes.AUTH_LOGIN_REQUEST_FAILED,
        errReason: errReason
    }
}

export function logout () {
    return {
        type: ActionTypes.AUTH_LOGOUT
    }
}

export function authTokenExpired () {
    return {
        type: ActionTypes.AUTH_TOKEN_EXPIRED
    }
}

import {
    AsyncSelectLayout,
    DateTimeControlLayout,
    DefaultLayout,
    EditorConfig,
    EntityStateControlLayout,
    FormLayout,
    GroupedLayout,
    ListLayout,
    NumberInputControl
} from '../components/editor/EditorLayout'
import { EntityClass } from '../store/types'
import {
    EmployeeApplicationEntity,
    EmployeeApplicationStatus,
    EmployeeApplicationStatusMeta,
    Entity,
    OrderEntity, OrderMaterialEntity,
    OrderMaterialStatus,
    OrderMaterialStatusMeta,
    OrderStatus,
    OrderStatusMeta
} from './types'
import moment from 'moment/moment'
import { getCars } from '../api/get-entity'

export interface ColumnSpec {
    title: string
    name: string

    format?: (value: any) => string
}

export interface FieldSpec {
    name: string
    type: 'int' | 'string'
}

const dateFormat = 'YYYY-MM-DD HH:mm'

const OrderSchema: Record<string, FieldSpec> = {
    id: { name: 'Номер', type: 'int' },
    name: { name: 'Марка', type: 'string' },
    volume: { name: 'Объем', type: 'string' },
    customer: { name: 'Заказчик', type: 'string' },
    date: { name: 'Дата и Время', type: 'string' },
    place: { name: 'Место', type: 'string' },
    tc: { name: 'АБС/ТС', type: 'string' },
    status: { name: 'Состояние', type: 'string' },
    note: { name: 'Примечание', type: 'string' }
}
const OrderTableSchema: ColumnSpec[] = [
    { name: 'id', title: '№' },
    { name: 'name', title: 'Марка' },
    { name: 'volume', title: 'Объем' },
    { name: 'customer', title: 'Заказчик' },
    { name: 'date', title: 'Дата и Время', format: (value: moment.Moment) => value.format(dateFormat) },
    { name: 'place', title: 'Место' },
    { name: 'tc', title: 'АБС/ТС' },
    { name: 'status', title: 'Состояние', format: (value: OrderStatus) => OrderStatusMeta[value].label }
]
const OrderEditorConfig: EditorConfig = {
    header: (entity: Entity) => {
        if (entity.id > 0) {
            return `Заказ номер ${entity.id} от ${entity.date.format(dateFormat)}`
        } else {
            return 'Новый заказ'
        }
    },
    layout: new FormLayout({
        id: 'root',
        layouts: [
            new GroupedLayout({
                id: 'body',
                layouts: [
                    new ListLayout({
                        id: 'left',
                        className: 'form-group col',
                        layouts: [
                            new DefaultLayout({ id: 'name' }),
                            new DateTimeControlLayout({ id: 'date' }),
                            new GroupedLayout({
                                id: 'tc-group',
                                layouts: [
                                    new AsyncSelectLayout({
                                        id: 'tc',
                                        loader: (inputValue, callback) => {
                                            // todo refactor; move loading to saga, add order -> car dependency
                                            const rawState = localStorage.getItem('persist:auth')
                                            if (rawState) {
                                                const state = JSON.parse(rawState)
                                                getCars({
                                                    baseUrl: process.env.REACT_APP_ENTITY_MODULE_API_BASE_URL || '',
                                                    token: JSON.parse(state.token),
                                                    userId: -1
                                                }, {})
                                                    .then(response => {
                                                        callback((response.data || []).map(item => { return { value: item.license, label: item.license } }))
                                                    })
                                                    .catch(() => {})
                                            }
                                        }
                                    }),
                                    new NumberInputControl({ id: 'volume', min: 0, precision: 1, step: 0.1, outputFormatter: 'string' })
                                ]
                            }),
                            new GroupedLayout({
                                id: 'customer-group',
                                layouts: [
                                    new DefaultLayout({ id: 'customer' }),
                                    new DefaultLayout({ id: 'place' })
                                ]
                            })
                        ]

                    }),
                    new ListLayout({
                        id: 'right',
                        className: 'form-group col-5',
                        layouts: [
                            new EntityStateControlLayout({
                                id: 'status',
                                stateMeta: OrderStatusMeta
                            }),
                            new DefaultLayout({ id: 'note', style: 'textarea' })
                        ]
                    })
                ]
            })
        ]
    })
}

const ApplicationSchema: Record<string, FieldSpec> = {
    id: { name: 'ID', type: 'int' },
    date: { name: 'Дата создания', type: 'string' },
    name: { name: 'Наименование', type: 'string' },
    count: { name: 'Количество', type: 'int' },
    price: { name: 'Стоимость', type: 'string' },
    target: { name: 'Кому', type: 'string' },
    status: { name: 'Состояние', type: 'string' },
    note: { name: 'Примечание', type: 'string' }
}
const ApplicationTableSchema: ColumnSpec[] = [
    { name: 'id', title: '№' },
    { name: 'date', title: 'Дата создания', format: (value: moment.Moment) => value.format(dateFormat) },
    { name: 'name', title: 'Наименование' },
    { name: 'count', title: 'Количество' },
    { name: 'price', title: 'Стоимость' },
    { name: 'target', title: 'Кому' },
    { name: 'status', title: 'Состояние', format: (value: EmployeeApplicationStatus) => EmployeeApplicationStatusMeta[value].label }
]
const ApplicationEditorConfig: EditorConfig = {
    header: (entity: Entity) => {
        if (entity.id > 0) {
            return `Заявление номер ${entity.id} от ${entity.date.format(dateFormat)}`
        } else {
            return 'Новое заявление'
        }
    },
    layout: new FormLayout({
        id: 'root',
        layouts: [
            new GroupedLayout({
                id: 'body',
                layouts: [
                    new ListLayout({
                        id: 'left',
                        className: 'form-group col',
                        layouts: [
                            new GroupedLayout({
                                id: 'order-group',
                                layouts: [
                                    new DefaultLayout({ id: 'name' }),
                                    new NumberInputControl({ id: 'count', min: 0 }),
                                    new NumberInputControl({ id: 'price', min: 0, precision: 2, step: 0.10, outputFormatter: 'string' })
                                ]
                            }),
                            new DefaultLayout({ id: 'target' })
                        ]
                    }),
                    new ListLayout({
                        id: 'right',
                        className: 'form-group col-5',
                        layouts: [
                            new EntityStateControlLayout({
                                id: 'status',
                                stateMeta: EmployeeApplicationStatusMeta
                            }),
                            new DefaultLayout({ id: 'note', style: 'textarea' })
                        ]
                    })
                ]
            })
        ]
    })
}

const MaterialSchema: Record<string, FieldSpec> = {
    id: { name: 'ID', type: 'int' },
    date: { name: 'Дата', type: 'string' },
    concrete: { name: 'Цемент', type: 'int' },
    master_glenim: { name: 'Добавка Master Glenim', type: 'int' },
    master_pozzolith: { name: 'Добавка Master Pozzolith', type: 'int' },
    master_air: { name: 'Добавка Master Air', type: 'int' },
    status: { name: 'Состояние', type: 'string' },
    note: { name: 'Примечание', type: 'string' }
}
const MaterialTableSchema: ColumnSpec[] = [
    { name: 'id', title: '№' },
    { name: 'date', title: 'Дата', format: (value: moment.Moment) => value.format(dateFormat) },
    { name: 'concrete', title: 'Цемент' },
    { name: 'master_glenim', title: 'Добавка Master Glenim' },
    { name: 'master_pozzolith', title: 'Добавка Master Pozzolith' },
    { name: 'master_air', title: 'Добавка Master Air' },
    { name: 'status', title: 'Состояние', format: (value: OrderMaterialStatus) => OrderMaterialStatusMeta[value].label }
]
const MaterialEditorConfig: EditorConfig = {
    header: (entity: Entity) => {
        if (entity.id > 0) {
            return `Заказ номер ${entity.id} от ${entity.date.format(dateFormat)}`
        } else {
            return 'Новый заказ'
        }
    },
    layout: new FormLayout({
        id: 'root',
        layouts: [
            new GroupedLayout({
                id: 'body',
                layouts: [
                    new ListLayout({
                        id: 'left',
                        className: 'form-group col',
                        layouts: [
                            new NumberInputControl({ id: 'concrete', min: 0 }),
                            new GroupedLayout({
                                id: 'master-group',
                                layouts: [
                                    new NumberInputControl({ id: 'master_glenim', min: 0 }),
                                    new NumberInputControl({ id: 'master_pozzolith', min: 0 }),
                                    new NumberInputControl({ id: 'master_air', min: 0 })
                                ]
                            })
                        ]
                    }),
                    new ListLayout({
                        id: 'right',
                        className: 'form-group col-5',
                        layouts: [
                            new EntityStateControlLayout({
                                id: 'status',
                                stateMeta: OrderMaterialStatusMeta
                            }),
                            new DefaultLayout({ id: 'note', style: 'textarea' })
                        ]
                    })
                ]
            })
        ]
    })
}

export function newDefaultEntity (entityClass: EntityClass): Entity {
    switch (entityClass) {
    case EntityClass.ORDER:
        return {
            id: -1,
            date: moment(),
            status: OrderStatus.ACCEPTED,
            name: '',
            volume: '0.0',
            customer: '',
            place: '',
            tc: 'Не указано',
            note: ''
        } as OrderEntity
    case EntityClass.ORDER_MATERIAL:
        return {
            id: -1,
            date: moment(),
            concrete: 0,
            master_glenim: 0,
            master_pozzolith: 0,
            master_air: 0,
            status: OrderMaterialStatus.DRAFT,
            note: ''
        } as OrderMaterialEntity
    case EntityClass.EMPLOYEE_APPLICATION:
        return {
            id: -1,
            date: moment(),
            name: '',
            count: 0,
            price: '0.0',
            target: '',
            status: EmployeeApplicationStatus.DRAFT,
            note: ''
        } as EmployeeApplicationEntity
    default:
        throw new Error('unsupported entity class')
    }
}

export {
    OrderEditorConfig,
    OrderSchema, ApplicationSchema, MaterialSchema,
    OrderTableSchema, ApplicationTableSchema, MaterialTableSchema,
    ApplicationEditorConfig, MaterialEditorConfig
}

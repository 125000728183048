import React from 'react'
import Table from 'react-bootstrap/Table'
import './TableBlock.css'
import { ColumnSpec } from '../../entity/schema'
import { Entity } from '../../entity/types'
import { connect } from 'react-redux'
import { AppState, TableEditorWorkspace } from '../../store/types'
import { Dispatch } from 'redux'
import { selectEntity, unselectEntity } from '../../store/action/select-entity'

interface EntityGroupSpec {
    format: (value: any) => string
}

interface GroupedTableSpec {
    groupSpec: EntityGroupSpec
    columnSpec: ColumnSpec[]
}

interface TableBlockProps {
    selectedEntityId?: number
    data: Entity[]
}

interface OwnTableBlockProps {
    spec: GroupedTableSpec
}

interface GroupedTableActions {
    selectEntity: (entity: Entity) => void
    unselectEntity: () => void
}

export class GroupedTable extends React.Component<TableBlockProps & OwnTableBlockProps & GroupedTableActions, {}> {
    constructor (props: TableBlockProps & OwnTableBlockProps & GroupedTableActions) {
        super(props)

        this.selectRow = this.selectRow.bind(this)
    }

    render (): React.ReactNode {
        return <div className={'tb-table'}>
            <Table bordered>
                <thead>
                    <tr>
                        { this.props.spec.columnSpec.map((column: ColumnSpec) => <th key={column.name}>{column.title}</th>) }
                    </tr>
                </thead>
                <tbody>
                    { Object.values(this.props.data).map((entity: Entity) => this.renderTableRow(entity)) }
                </tbody>
            </Table>
        </div>
    }

    renderTableRow (entityGroup: Entity): React.ReactNode {
        return <>
            <tr key={entityGroup.name} className={'tb-row-group-header'}>
                <td colSpan={this.props.spec.columnSpec.length}>{this.props.spec.groupSpec.format(entityGroup.name)}</td>
            </tr>
            { Object.values(entityGroup.entities as Entity[]).map((entity: Entity, index: number) => {
                return <>
                    <tr key={index}
                        onClick={() => this.selectRow(entity)}
                        className={this.props.selectedEntityId === entity.id ? 'tb-row-selected' : ''}
                    >
                        { this.props.spec.columnSpec.map((column: ColumnSpec) => <td key={column.name}>{this.formatCellValue(column, entity[column.name])}</td>) }
                    </tr>
                </>
            })}
        </>
    }

    selectRow (entity: Entity): void {
        if (this.props.selectedEntityId === entity.id) {
            this.props.unselectEntity()
        } else {
            this.props.selectEntity(entity)
        }
    }

    formatCellValue (column: ColumnSpec, value: any): any {
        if (column.format) {
            return column.format(value)
        } else {
            return value
        }
    }
}

const mapStateToProps = (state: AppState, ownProps: OwnTableBlockProps): TableBlockProps & OwnTableBlockProps => {
    const currentEntityClass = state.workspace.currentEntityClass
    const currentWorkspace = state.workspace.workspaces[currentEntityClass] as TableEditorWorkspace
    const meta = currentWorkspace.selectedEntity?.meta
    let selectedEntityId: number | undefined
    if (meta && !meta.isNew) {
        selectedEntityId = meta.entityId
    }
    return {
        spec: ownProps.spec,
        data: state.data.dataset[currentEntityClass],
        selectedEntityId: selectedEntityId
    }
}

const mapDispatchToProps = (dispatch: Dispatch): GroupedTableActions => {
    return {
        selectEntity: (entity: Entity) => dispatch(selectEntity(entity)),
        unselectEntity: () => dispatch(unselectEntity())
    }
}

export const ConnectedGroupedTable = connect(mapStateToProps, mapDispatchToProps)(GroupedTable)

export type {
    GroupedTableSpec
}

interface ClientConfig {
    baseUrl: string
    token: string
    userId: number // used only in getAppNotifications. todo refactor
}

enum ErrorReason {
    UNKNOWN_ERROR,
    TOKEN_EXPIRED,
    WRONG_USER_OR_PASS,
    INVALID_TOKEN,
    TIMEOUT,
    SERVER_SIDE
}

interface ApiResponse<T> {
    data?: T,
    errorReason?: ErrorReason
}

export type {
    ApiResponse, ClientConfig
}

export {
    ErrorReason
}

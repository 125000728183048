import { AnyAction } from 'redux'
import { ActionTypes } from './action'

export function notificationConfirmRequest (id: number): AnyAction {
    return {
        type: ActionTypes.NOTIFICATION_CONFIRM_REQUEST,
        id: id
    }
}

export function notificationConfirmRequestSucceeded (id: number): AnyAction {
    return {
        type: ActionTypes.NOTIFICATION_CONFIRM_REQUEST_SUCCEEDED,
        id: id
    }
}

export function notificationConfirmRequestFailed (): AnyAction {
    return {
        type: ActionTypes.NOTIFICATION_CONFIRM_REQUEST_FAILED
    }
}

import { Auth, User } from '../types'
import { AnyAction } from 'redux'
import { ActionTypes } from '../action/action'
import { ErrorReason } from '../../api/types'

const initState: Auth = {
    isLogged: false,
    token: '',
    expiredTime: -1,
    userInfo: {
        userId: -1,
        permissions: [],
        firstName: 'undefined',
        lastName: 'undefined',
        roleName: 'undefined'
    },
    errMessage: undefined
}

export function authReducer (state: Auth = initState, action: AnyAction): Auth {
    switch (action.type) {
    case ActionTypes.AUTH_LOGIN_REQUEST_SUCCEEDED: {
        const user: User = action.user
        return {
            isLogged: true,
            token: action.token,
            expiredTime: action.expirationTime,
            userInfo: user
        }
    }
    case ActionTypes.AUTH_LOGIN_REQUEST_FAILED: {
        let errMessage: string
        switch (action.errReason) {
        case ErrorReason.WRONG_USER_OR_PASS:
            errMessage = 'Неверное имя пользователя или пароль'
            break
        default:
            errMessage = 'Неизвестная ошибка. Попробуйте позже или обратитесь к администратору'
            break
        }
        return {
            ...state,
            errMessage: errMessage
        }
    }
    case ActionTypes.AUTH_LOGOUT:
        return {
            ...initState
        }
    case ActionTypes.AUTH_TOKEN_EXPIRED:
        return {
            ...initState,
            errMessage: 'Сессия истекла!'
        }
    }

    return state
}

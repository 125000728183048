import React, { ChangeEvent, FormEvent } from 'react'

import './Login.css'
import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { loginRequest } from '../../store/action/auth'
import { AppState } from '../../store/types'

interface LoginProps {
    errMessage?: string
}

interface LoginActions {
    login: (username: string, password: string) => void
}

interface LoginState {
    username: string
    password: string
}

class Login extends React.Component<LoginProps & LoginActions, LoginState> {
    constructor (props: LoginProps & LoginActions) {
        super(props)

        this.state = {
            username: '',
            password: ''
        }

        this.onChangeInput = this.onChangeInput.bind(this)
    }

    render () {
        return <div className={'login-block'}>
            <div className={'login-form-block'}>
                <h2 className={'login-form-header'}>Вход</h2>
                <Form onSubmit={(e: FormEvent) => {
                    e.preventDefault()
                    this.props.login(this.state.username, this.state.password)
                }}>
                    <Form.Group>
                        <Form.Label>Имя пользователя</Form.Label>
                        <Form.Control name="username" value={this.state.username} onChange={this.onChangeInput}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control name="password" type="password" value={this.state.password} onChange={this.onChangeInput}/>
                    </Form.Group>
                    {this.renderErrMessage()}
                    <Button variant="success" type="submit" block>Войти</Button>
                </Form>
            </div>
        </div>
    }

    renderErrMessage (): React.ReactNode | undefined {
        if (this.props.errMessage) {
            return <div className={'login-form-err'}>{this.props.errMessage}</div>
        }
    }

    onChangeInput (event: ChangeEvent<HTMLInputElement>) {
        switch (event.target.name) {
        case 'username':
            this.setState({
                username: event.target.value
            })
            break
        case 'password':
            this.setState({
                password: event.target.value
            })
            break
        }
    }
}

const mapStateToProps = (state: AppState): LoginProps => {
    return {
        errMessage: state.auth.errMessage
    }
}

const mapDispatchToProps = (dispatch: Dispatch): LoginActions => {
    return {
        login: (username: string, password:string) => dispatch(loginRequest(username, password))
    }
}

export const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(Login)

import { Entity, EntityMeta } from '../entity/types'

enum EntityClass {
    ORDER, ORDER_MATERIAL, EMPLOYEE_APPLICATION, NOTIFICATION
}

export interface EntityInfo {
    entity: Entity
    meta: EntityMeta
}

interface ListWorkspace {
    entityClass: EntityClass // todo consider to remove
}

interface TableEditorWorkspace {
    entityClass: EntityClass // todo consider to remove
    selectedEntity?: EntityInfo
    dataFilter: DataFilter
}

interface Workspace {
    loading: boolean
    currentEntityClass: EntityClass
    workspaces: Record<EntityClass, ListWorkspace | TableEditorWorkspace>
}

interface DataFilter {
    filterCompletedRecords: boolean
}

interface Dataset {
    dataset: Record<EntityClass, Entity[]>
}

interface User {
    userId: number
    permissions: string[]

    firstName: string
    lastName: string
    roleName: string
}

interface Auth {
    isLogged: boolean
    token: string
    expiredTime: number
    userInfo: User

    errMessage?: string
}

interface AppState {
    auth: Auth
    workspace: Workspace
    data: Dataset
}

const initState: AppState = {
    auth: {
        isLogged: false,
        token: '',
        expiredTime: -1,
        userInfo: {
            userId: -1,
            permissions: [],
            firstName: 'undefined',
            lastName: 'undefined',
            roleName: 'undefined'
        }
    },
    workspace: {
        loading: false,
        currentEntityClass: EntityClass.ORDER,
        workspaces: {
            [EntityClass.ORDER]: {
                entityClass: EntityClass.ORDER,
                dataFilter: {
                    filterCompletedRecords: false
                }
            },
            [EntityClass.ORDER_MATERIAL]: {
                entityClass: EntityClass.ORDER_MATERIAL,
                dataFilter: {
                    filterCompletedRecords: false
                }
            },
            [EntityClass.EMPLOYEE_APPLICATION]: {
                entityClass: EntityClass.EMPLOYEE_APPLICATION,
                dataFilter: {
                    filterCompletedRecords: false
                }
            },
            [EntityClass.NOTIFICATION]: {
                entityClass: EntityClass.NOTIFICATION
            }
        }
    },
    data: {
        dataset: {
            [EntityClass.ORDER]: [],
            [EntityClass.ORDER_MATERIAL]: [],
            [EntityClass.EMPLOYEE_APPLICATION]: [],
            [EntityClass.NOTIFICATION]: []
        }
    }
}

export type { AppState, Workspace, Auth, User, DataFilter, Dataset, ListWorkspace, TableEditorWorkspace }
export { EntityClass, initState }

import { Col, Form } from 'react-bootstrap'
import Select, { ValueType } from 'react-select'
import AsyncSelect from 'react-select/async'
import React from 'react'
import { Entity, StatusMeta } from '../../entity/types'
import { ClassNameProps } from '../types'
import { OptionsType, OptionTypeBase } from 'react-select/src/types'
import { ChangeEvent } from './Editor'
import NumericInput from 'react-numeric-input'

import Datetime from 'react-datetime'
import 'moment/locale/ru'
import moment, { Moment } from 'moment/moment'
import { EntityInfo } from '../../store/types'

interface EditorConfig {
    header: string | ((entity: Entity) => string)
    layout: EditorLayout<CombinedLayoutConfig>
}

interface EditorLayoutConfig {
    id: string
}

export abstract class EditorLayout<T extends EditorLayoutConfig> {
    readonly config: T

    protected constructor (config: T) {
        this.config = config
    }

    abstract renderLayout(schema: Record<string, any>,
                          entity: EntityInfo,
                          onChange?: (event: ChangeEvent) => void,
                          props?: any): any
}

interface ReadOnlyLayoutConfig extends EditorLayoutConfig {
    style?: 'label' | 'header'
    format?: (schema: Record<string, any>, entity: Entity) => string
}

class ReadOnlyLayout extends EditorLayout<ReadOnlyLayoutConfig> {
    private readonly format: (schema: Record<string, any>, entity: Entity) => string

    constructor (config: ReadOnlyLayoutConfig) {
        super(config)

        if (config.format) {
            this.format = config.format
        } else {
            const fieldName = this.config.id
            this.format = (schema: Record<string, any>, entity: Entity): string => `${schema[fieldName].name}: ${entity[fieldName]}`
        }
        if (!config.style) {
            config.style = 'label'
        }
    }

    renderLayout (schema: Record<string, any>, entity: EntityInfo): any {
        const value = this.format(schema, entity.entity)
        switch (this.config.style) {
        case 'header':
            return <h4 key={this.config.id} className={'eb-header'}>{value}</h4>
        case 'label':
            return <Form.Label>{value}</Form.Label>
        }
    }
}

interface DefaultLayoutConfig extends EditorLayoutConfig {
    style?: 'textarea' | 'input'
}

class DefaultLayout extends EditorLayout<DefaultLayoutConfig> {
    // eslint-disable-next-line
    constructor(config: DefaultLayoutConfig) {
        super(config)

        if (!config.style) {
            config.style = 'input'
        }
    }

    renderLayout (schema: Record<string, any>, entity: EntityInfo, onChange: (event: ChangeEvent) => void, props?: any): any {
        const field = this.config.id
        const value = entity.entity[field]
        return <Form.Group key={field} {...props}>
            <Form.Label>{schema[field].name}</Form.Label>
            <Form.Control name={field} type="text" value={value} as={this.config.style}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(this.onChangeAdapter(event))}
            />
        </Form.Group>
    }

    onChangeAdapter (event: React.ChangeEvent<HTMLInputElement>): ChangeEvent {
        return {
            field: event.target.name,
            value: event.target.value
        }
    }
}

interface NumberInputControlConfig extends EditorLayoutConfig {
    step?: number
    max?: number
    min?: number
    precision?: number

    outputFormatter?: 'int' | 'string'
}

class NumberInputControl extends EditorLayout<NumberInputControlConfig> {
    private readonly outputValueFormatter: (value: number | null) => number | string

    constructor (config: NumberInputControlConfig) {
        super(config)

        if (!config.outputFormatter) {
            config.outputFormatter = 'int'
        }
        if (config.outputFormatter === 'int') {
            this.outputValueFormatter = (value: number | null) => value || 0
        } else {
            this.outputValueFormatter = (value: number | null) => `${value || 0}`
        }
    }

    renderLayout (schema: Record<string, any>, entity: EntityInfo, onChange: ((event: ChangeEvent) => void), props?: any): any {
        const field = this.config.id
        const value = entity.entity[field]
        return <Form.Group key={field} {...props}>
            <Form.Label>{schema[field].name}</Form.Label>
            <NumericInput name={field} className={'form-control'}
                step={this.config.step} min={this.config.min} max={this.config.max} precision={this.config.precision}
                value={value} onChange={(value: number | null) => onChange({ field: field, value: this.outputValueFormatter(value) })}/>
        </Form.Group>
    }
}

interface SelectLayoutConfig extends EditorLayoutConfig {
    items: Record<string, OptionTypeBase>
}

class SelectLayout extends EditorLayout<SelectLayoutConfig> {
    // eslint-disable-next-line
    constructor(props: SelectLayoutConfig) {
        super(props)
    }

    renderLayout (schema: Record<string, any>, entity: EntityInfo, onChange: ((event: ChangeEvent) => void), props?: any): any {
        const field = this.config.id
        const value = entity.entity[field]
        return <Form.Group key={field} {...props}>
            <Form.Label>{schema[field].name}</Form.Label>
            <Select name={field} options={Object.values(this.config.items)} value={this.config.items[value]}
                onChange={(event: ValueType<OptionTypeBase, false>) => onChange({ field: field, value: event?.value })}
            />
        </Form.Group>
    }
}

interface EntityStateControlLayoutConfig extends EditorLayoutConfig {
    stateMeta: Record<any, StatusMeta>
}

class EntityStateControlLayout extends EditorLayout<EntityStateControlLayoutConfig> {
    // eslint-disable-next-line
    constructor(props: EntityStateControlLayoutConfig) {
        super(props)
    }

    renderLayout (schema: Record<string, any>, entity: EntityInfo, onChange: ((event: ChangeEvent) => void), props?: any): any {
        const field = this.config.id
        const value = entity.entity[field]
        const currentStatusMeta = this.config.stateMeta[value]
        return <Form.Group key={field} {...props}>
            <Form.Label>{schema[field].name}</Form.Label>
            <Select name={field}
                isDisabled={currentStatusMeta.nextStatuses.length === 0 || entity.meta.isNew}
                options={Object.values(currentStatusMeta.nextStatuses).map(status => this.config.stateMeta[status])}
                value={currentStatusMeta}
                onChange={(event: ValueType<OptionTypeBase, false>) => onChange({ field: field, value: event?.value })}
            />
        </Form.Group>
    }
}

interface AsyncSelectLayoutConfig extends EditorLayoutConfig {
    loader: (inputValue: string, callback: ((options: OptionsType<OptionTypeBase>) => void)) => Promise<any> | void;
}

// todo tests
class AsyncSelectLayout extends EditorLayout<AsyncSelectLayoutConfig> {
    // eslint-disable-next-line
    constructor(props: AsyncSelectLayoutConfig) {
        super(props)
    }

    renderLayout (schema: Record<string, any>, entity: EntityInfo, onChange: ((event: ChangeEvent) => void), props?: any): any {
        const field = this.config.id
        const value = entity.entity[field]
        return <Form.Group key={field} {...props}>
            <Form.Label>{schema[field].name}</Form.Label>
            <AsyncSelect name={field} defaultOptions cacheOptions
                loadOptions={this.config.loader} value={{ value: value, label: value }}
                onChange={(event: ValueType<OptionTypeBase, false>) => onChange({ field: field, value: event?.value })}
            />
        </Form.Group>
    }
}

class DateTimeControlLayout extends EditorLayout<EditorLayoutConfig> {
    // eslint-disable-next-line
    constructor(config: EditorLayoutConfig) {
        super(config)
    }

    renderLayout (schema: Record<string, any>, entity: EntityInfo, onChange: ((event: ChangeEvent) => void), props?: any): any {
        const field = this.config.id
        const value = entity.entity[field]
        return <Form.Group key={field} {...props}>
            <Form.Label>{schema[field].name}</Form.Label>
            <Datetime locale={'ru'}
                value={moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ')}
                onChange={(value: Moment | string) => {
                    let result: Moment
                    if (typeof value === 'string') {
                        result = moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
                    } else {
                        result = value
                    }
                    onChange({ field: field, value: result })
                }}/>
        </Form.Group>
    }
}

interface CombinedLayoutConfig extends EditorLayoutConfig {
    layouts: EditorLayout<any>[]
}

class GroupedLayout extends EditorLayout<CombinedLayoutConfig> {
    // eslint-disable-next-line
    constructor(config: CombinedLayoutConfig) {
        super(config)
    }

    renderLayout (schema: Record<string, any>, entity: EntityInfo, onChange: (event: ChangeEvent) => void): any {
        return <Form.Row key={this.config.id}>
            {this.config.layouts.map(layout => layout.renderLayout(schema, entity, onChange, { as: Col }))}
        </Form.Row>
    }
}

class ListLayout extends EditorLayout<CombinedLayoutConfig & ClassNameProps> {
    // eslint-disable-next-line
    constructor(config: CombinedLayoutConfig & ClassNameProps) {
        super(config)
    }

    renderLayout (schema: Record<string, any>, entity: EntityInfo, onChange?: ((event: ChangeEvent) => void)): any {
        return <div className={this.config.className}>{this.config.layouts.map(layout => layout.renderLayout(schema, entity, onChange))}</div>
    }
}

class FormLayout extends ListLayout {
    // eslint-disable-next-line
    constructor(config: CombinedLayoutConfig) {
        super(config)
    }

    renderLayout (schema: Record<string, any>, entity: EntityInfo, onChange?: ((event: ChangeEvent) => void)): any {
        return <Form>{this.config.layouts.map(layout => layout.renderLayout(schema, entity, onChange))}</Form>
    }
}

export type { EditorConfig }

export {
    FormLayout, GroupedLayout, DefaultLayout, ReadOnlyLayout, ListLayout, SelectLayout,
    EntityStateControlLayout, DateTimeControlLayout, NumberInputControl, AsyncSelectLayout
}

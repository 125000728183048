import React from 'react'

import { connect } from 'react-redux'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import { EditorConfig } from './EditorLayout'
import { AppState, EntityClass, EntityInfo } from '../../store/types'
import { ClassNameProps } from '../types'
import { Entity } from '../../entity/types'

import './Editor.css'
import { Dispatch } from 'redux'
import { createEntityRequest, updateEntity } from '../../store/action/entity-actions'
import { FieldSpec } from '../../entity/schema'
import { unselectEntity } from '../../store/action/select-entity'

interface EditorProps {
    entity?: Readonly<EntityInfo>

    allowCreateAction?: boolean
    allowUpdateAction?: boolean
}

interface OwnEditorProps {
    schema: Record<string, FieldSpec>
    editorLayout: EditorConfig
}

interface EditorActions {
    createEntity: (entityClass: EntityClass, entity: Entity) => void,
    updateEntity: (entityClass: EntityClass, entity: Entity) => void,
    clearEditor: () => void,
}

interface EditorState {
    entity?: EntityInfo
    isChanged: boolean
}

export interface ChangeEvent {
    field: string,
    value: any
}

class Editor extends React.Component<EditorProps & OwnEditorProps & EditorActions & ClassNameProps, EditorState> {
    // eslint-disable-next-line
    constructor(props: EditorProps & OwnEditorProps & EditorActions & ClassNameProps) {
        super(props)

        this.state = {
            entity: props.entity ? { entity: { ...props.entity.entity }, meta: { ...props.entity.meta } } : undefined,
            isChanged: false
        }
        this.onChangeInput = this.onChangeInput.bind(this)
        this.saveChanges = this.saveChanges.bind(this)
        this.cancelChanges = this.cancelChanges.bind(this)
        this.saveChangesCondition = this.saveChangesCondition.bind(this)
        this.getEditorHeader = this.getEditorHeader.bind(this)
    }

    static getDerivedStateFromProps (props: EditorProps, state: EditorState): EditorState {
        const entity = props.entity
        if (!entity) {
            return { entity: undefined, isChanged: false }
        }
        if (entity.meta.entityId !== state.entity?.meta.entityId || entity.meta.entityClass !== state.entity?.meta.entityClass) {
            return {
                entity: { entity: { ...entity.entity }, meta: { ...entity.meta } },
                isChanged: false
            }
        }
        return state
    }

    render () {
        return <div className={this.props.className}>
            { this.state.entity ? this.renderEditorLayouts(this.state.entity) : this.renderJumbotron() }
        </div>
    }

    renderEditorLayouts (entity: EntityInfo): any {
        return <div className="eb-root">
            <div className={'eb-header'}>
                <h4>{this.getEditorHeader()}</h4>
            </div>
            <div className={'eb-layout'}>
                {this.props.editorLayout.layout.renderLayout(this.props.schema, entity, this.onChangeInput)}
                <ButtonGroup>
                    <Button
                        onClick={this.saveChanges}
                        disabled={!this.saveChangesCondition() || !this.state.isChanged}
                    >
                        Сохранить
                    </Button>
                    <Button
                        onClick={this.cancelChanges}
                        disabled={!this.saveChangesCondition() || (!this.state.isChanged && !this.state.entity?.meta.isNew)}
                    >
                        Отменить
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    }

    getEditorHeader (): string {
        if (typeof this.props.editorLayout.header === 'string') {
            return this.props.editorLayout.header
        } else {
            return this.props.editorLayout.header(this.state.entity?.entity || { id: -1 })
        }
    }

    renderJumbotron (): any {
        return <div className="eb-jumbotron">
            <h2>Запись не выбрана</h2>
        </div>
    }

    onChangeInput (event: ChangeEvent) {
        if (this.state.entity) {
            const newEntityState = this.state.entity.entity

            switch (this.props.schema[event.field].type) {
            case 'string':
                newEntityState[event.field] = event.value
                break
            case 'int':
                newEntityState[event.field] = Number.parseInt(event.value)
                break
            }

            this.setState({
                entity: { ...this.state.entity, entity: newEntityState },
                isChanged: true
            })
        }
    }

    saveChanges () {
        const entity = this.state.entity
        if (entity) {
            const meta = entity.meta
            if (meta.isNew) {
                this.props.createEntity(meta.entityClass!!, entity.entity)
            } else {
                this.props.updateEntity(meta.entityClass!!, entity.entity)
            }
        }
    }

    saveChangesCondition () {
        const entity = this.state.entity
        if (entity) {
            if (entity.meta.isNew) {
                return this.props.allowCreateAction
            } else {
                return this.props.allowUpdateAction
            }
        }
        return false
    }

    cancelChanges () {
        const entity = this.props.entity
        if (entity) {
            if (entity.meta.isNew) {
                this.props.clearEditor()
            } else {
                this.setState({
                    entity: { entity: { ...entity.entity }, meta: { ...entity.meta } },
                    isChanged: false
                })
            }
        }
    }
}

const mapStateToProps = (state: AppState, ownProps: OwnEditorProps & ClassNameProps): EditorProps & OwnEditorProps & ClassNameProps => {
    const permissions = state.auth.userInfo.permissions
    const entityClass = state.workspace.currentEntityClass
    const currentWorkspace = state.workspace.workspaces[entityClass]

    let entity: EntityInfo | undefined
    if ('dataFilter' in currentWorkspace) {
        entity = currentWorkspace.selectedEntity
    }

    return {
        className: ownProps.className,
        schema: ownProps.schema,
        editorLayout: ownProps.editorLayout,

        entity: entity,

        allowCreateAction: permissions.includes(`${entityClass}c`),
        allowUpdateAction: permissions.includes(`${entityClass}u`)
    }
}

const mapDispatchToProps = (dispatch: Dispatch): EditorActions => {
    return {
        createEntity: (entityClass: EntityClass, entity: Entity) => dispatch(createEntityRequest(entityClass, entity)),
        updateEntity: (entityClass: EntityClass, entity: Entity) => dispatch(updateEntity(entityClass, entity)),
        clearEditor: () => dispatch(unselectEntity())
    }
}

const ConnectedEditor = connect(mapStateToProps, mapDispatchToProps)(Editor)

export { Editor, ConnectedEditor }

import { call, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../action/action'
import { AnyAction } from 'redux'
import { confirmAppNotification } from '../../api/notification-api'
import { notificationConfirmRequestFailed, notificationConfirmRequestSucceeded } from '../action/notification'
import { ApiResponse } from '../../api/types'

export function * notificationConfirmRequest (action: AnyAction) {
    const response: ApiResponse<boolean> = yield call(confirmAppNotification, action.clientConfig, action.id)
    if (!response.errorReason && response.data) {
        yield put(notificationConfirmRequestSucceeded(action.id))
    } else {
        yield put(notificationConfirmRequestFailed())
    }
}

export function * notificationSagas () {
    yield takeEvery(ActionTypes.NOTIFICATION_CONFIRM_REQUEST, notificationConfirmRequest)
}

import { Dispatch, MiddlewareAPI } from 'redux'
import { ActionTypes } from '../action/action'
import { AppState } from '../types'

const authApiBaseUrl = process.env.REACT_APP_AUTH_MODULE_API_BASE_URL
const entityApiBaseUrl = process.env.REACT_APP_ENTITY_MODULE_API_BASE_URL

export const apiRequestMiddleware = (store: MiddlewareAPI<Dispatch, AppState>) => (next: Dispatch) => (action: any) => {
    if (typeof action === 'object' && action !== null) {
        if (Object.prototype.hasOwnProperty.call(action, 'type')) {
            switch (action.type) {
            case ActionTypes.AUTH_LOGIN_REQUEST: {
                action.clientConfig = { baseUrl: authApiBaseUrl }
                break
            }
            case ActionTypes.CREATE_ENTITY_REQUEST:
            case ActionTypes.UPDATE_ENTITY:
            case ActionTypes.DELETE_ENTITY:
            case ActionTypes.FETCH_DATASET_PAGE:
            case ActionTypes.NOTIFICATION_CONFIRM_REQUEST: {
                const token = store.getState().auth.token
                const userId = store.getState().auth.userInfo.userId
                action.clientConfig = { baseUrl: entityApiBaseUrl, token: token, userId: userId }
                break
            }
            }
        }
    }

    return next(action)
}

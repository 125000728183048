import { ActionTypes } from './action'
import { Entity } from '../../entity/types'

export function selectEntity (entity: Entity) {
    return {
        type: ActionTypes.SELECT_ENTITY,
        target: entity
    }
}

export function unselectEntity () {
    return {
        type: ActionTypes.UNSELECT_ENTITY
    }
}

export interface DataFilterChanges {
    filterCompletedRecords?: boolean
}

export function changeDataFilter (changes: DataFilterChanges) {
    return {
        type: ActionTypes.WORKSPACE_PRE_CHANGE_DATA_FILTER,
        changes: changes
    }
}

import React from 'react'

import './UserBlock.css'
import { connect } from 'react-redux'
import { AppState } from '../../store/types'

interface UserBlockProps {
    userFirstName: string
    userSecondName: string
    roleName: string
}

export class UserBlock extends React.Component<UserBlockProps, {}> {
    render () {
        return <div className={'ub-root'}>
            <div className={'ub-icon'}>
                <svg width="56" height="56">
                    <circle cx="28" cy="28" r="28" fill="#aeb3b7"/>
                    <text fill="#e9ecef" fontSize="24" fontFamily="sans-serif" y="50%" x="50%" textAnchor="middle" dominantBaseline="central" >
                        {this.props.userSecondName[0] + this.props.userFirstName[0]}
                    </text>
                </svg>
            </div>
            <div>
                <div className={'ub-info-name'}>{this.props.userSecondName} {this.props.userFirstName}</div>
                <div className={'ub-info-role'}>{this.props.roleName}</div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: AppState): UserBlockProps => {
    return {
        userFirstName: state.auth.userInfo.firstName,
        userSecondName: state.auth.userInfo.lastName,
        roleName: state.auth.userInfo.roleName
    }
}

export const ConnectedUserBlock = connect(mapStateToProps)(UserBlock)

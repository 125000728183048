import axios, { AxiosResponse } from 'axios'
import { User } from '../store/types'
import { ApiResponse, ClientConfig, ErrorReason } from './types'

interface AuthRequestData {
    username: string
    password: string
    project: string
}

export interface AuthResponseData {
    token: string
    userInfo: User
    expiredTime: number
}

export function login (config: ClientConfig, requestData: AuthRequestData): Promise<ApiResponse<AuthResponseData>> {
    return axios
        .post(
            '/auth/v1/login',
            { ...requestData },
            {
                baseURL: config.baseUrl,
                timeout: 5000,
                headers: {
                    'Content-Type': 'application/json'
                },
                validateStatus: () => true
            }
        )
        .then((response: AxiosResponse): ApiResponse<AuthResponseData> => {
            switch (response.status) {
            case 200: {
                const token = response.headers.token
                const payload = JSON.parse(atob(token.split('.')[1]))

                const tokenExpirationTime = payload.exp
                const userPermissions = payload.per
                const userId = payload.usr

                return {
                    data: {
                        token: token,
                        expiredTime: tokenExpirationTime,
                        userInfo: {
                            userId: userId,
                            permissions: userPermissions,

                            firstName: response.data.first_name,
                            lastName: response.data.last_name,
                            roleName: response.data.role_name
                        }
                    }
                }
            }
            case 401: {
                return {
                    errorReason: ErrorReason.WRONG_USER_OR_PASS
                }
            }
            default: {
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR
                }
            }
            }
        })
}

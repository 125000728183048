import { EntityClass } from '../store/types'
import moment from 'moment/moment'

interface Entity {
    id: number
    [key: string]: any
}

interface EntityMeta {
    entityClass?: EntityClass
    entityId?: number
    isNew?: boolean
}

// region entity statuses
interface StatusMeta {
    value: string
    label: string

    nextStatuses: string[]
}

enum OrderMaterialStatus {
    DRAFT = 'DRAFT',
    SUBMITTED = 'SUBMITTED',
}

const OrderMaterialStatusMeta: Record<OrderMaterialStatus, StatusMeta> = {
    [OrderMaterialStatus.DRAFT]: { value: OrderMaterialStatus.DRAFT, label: 'Черновик', nextStatuses: [OrderMaterialStatus.SUBMITTED] },
    [OrderMaterialStatus.SUBMITTED]: { value: OrderMaterialStatus.SUBMITTED, label: 'Отправлено', nextStatuses: [] }
}

enum OrderStatus {
    ACCEPTED = 'ACCEPTED',
    SHIPPED = 'SHIPPED',
    DELAY = 'DELAY',
    COMPLETED = 'COMPLETED',
}

const OrderStatusMeta: Record<OrderStatus, StatusMeta> = {
    [OrderStatus.ACCEPTED]: { value: OrderStatus.ACCEPTED, label: 'Принято', nextStatuses: [OrderStatus.SHIPPED] },
    [OrderStatus.SHIPPED]: { value: OrderStatus.SHIPPED, label: 'Отгружено', nextStatuses: [OrderStatus.DELAY, OrderStatus.COMPLETED] },
    [OrderStatus.DELAY]: { value: OrderStatus.DELAY, label: 'Задержка', nextStatuses: [OrderStatus.COMPLETED] },
    [OrderStatus.COMPLETED]: { value: OrderStatus.COMPLETED, label: 'Завершено', nextStatuses: [] }
}

enum EmployeeApplicationStatus {
    DRAFT = 'DRAFT',
    SUBMITTED = 'SUBMITTED',
    REJECTED = 'REJECTED',
    ACCEPTED = 'ACCEPTED',
    COMPLETED = 'COMPLETED',
}

const EmployeeApplicationStatusMeta: Record<EmployeeApplicationStatus, StatusMeta> = {
    [EmployeeApplicationStatus.DRAFT]: { value: EmployeeApplicationStatus.DRAFT, label: 'Черновик', nextStatuses: [EmployeeApplicationStatus.SUBMITTED] },
    [EmployeeApplicationStatus.SUBMITTED]: { value: EmployeeApplicationStatus.SUBMITTED, label: 'Отправлено', nextStatuses: [EmployeeApplicationStatus.REJECTED, EmployeeApplicationStatus.ACCEPTED] },
    [EmployeeApplicationStatus.REJECTED]: { value: EmployeeApplicationStatus.REJECTED, label: 'Отклонено', nextStatuses: [] },
    [EmployeeApplicationStatus.ACCEPTED]: { value: EmployeeApplicationStatus.ACCEPTED, label: 'Принято', nextStatuses: [EmployeeApplicationStatus.COMPLETED] },
    [EmployeeApplicationStatus.COMPLETED]: { value: EmployeeApplicationStatus.COMPLETED, label: 'Завершено', nextStatuses: [] }
}
// endregion entity statuses

interface EntityGroup<T> extends Entity {
    name: string
    entities: T[]
}

// region entities
interface OrderEntity extends Entity {
    date: moment.Moment
    name: string
    volume: string
    customer: string
    place: string
    tc: string
    note?: string
    status: OrderStatus
}

/* eslint-disable camelcase */
interface OrderMaterialEntity extends Entity {
    date: moment.Moment
    concrete: number
    master_glenim: number
    master_pozzolith: number
    master_air: number
    note?: string
    status: OrderMaterialStatus
}
/* eslint-enable camelcase  */

interface EmployeeApplicationEntity extends Entity {
    date: moment.Moment
    name: string
    count: number
    price: string
    target: string
    note?: string
    status: EmployeeApplicationStatus
}
// endregion entities

// region notification
enum NotificationSeverity {
    INFO = 'INFO',
    WARNING = 'WARNING',
    CRITICAL = 'CRITICAL'
}

interface NotificationEntity extends Entity {
    date: moment.Moment
    text: string
    severity: NotificationSeverity
}
// endregion

interface CarEntity extends Entity {
    license: string
}

export type {
    Entity, EntityMeta, StatusMeta, EntityGroup,
    OrderEntity, OrderMaterialEntity, EmployeeApplicationEntity,
    NotificationEntity, CarEntity
}

export {
    OrderStatus, OrderMaterialStatus, EmployeeApplicationStatus,
    OrderStatusMeta, OrderMaterialStatusMeta, EmployeeApplicationStatusMeta,
    NotificationSeverity
}

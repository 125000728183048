import React from 'react'

import './LoadingOverlay.css'

export class LoadingOverlay extends React.Component<{}, {}> {
    render () {
        return <div className={'cmp-loading-overlay'}>
            <div className={'cmp-loading-overlay-spinner'} />
            <div>Загрузка...</div>
        </div>
    }
}

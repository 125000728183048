import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { NotificationEntity, NotificationSeverity } from '../../entity/types'

import './NotificationList.css'
import { connect } from 'react-redux'
import { AppState, EntityClass } from '../../store/types'
import { Dispatch } from 'redux'
import { notificationConfirmRequest } from '../../store/action/notification'

interface NotificationListProps {
    data: NotificationEntity[]
}

interface NotificationListActions {
    confirm: (id: number) => void
}

export class NotificationList extends React.Component<NotificationListProps & NotificationListActions, {}> {
    render () {
        return <div className={'notification-list-root'}>
            <h2 className={'notification-list-header'}>Уведомления</h2>
            <ListGroup variant="flush" className={'notification-list-group'}>
                {this.props.data.map((notification: NotificationEntity) => this.renderListItem(notification))}
            </ListGroup>
        </div>
    }

    renderListItem (notification: NotificationEntity): React.ReactNode {
        let variant: string
        switch (notification.severity) {
        case NotificationSeverity.INFO:
            variant = ''
            break
        case NotificationSeverity.WARNING:
            variant = 'warning'
            break
        case NotificationSeverity.CRITICAL:
            variant = 'danger'
            break
        }
        return <ListGroup.Item key={notification.id} variant={variant}>
            <div className="row">
                <div className="col">{notification.date.format('YYYY-MM-DD hh:mm:ss')}</div>
                <div className="col-9">{notification.text}</div>
                <div className="col-auto" onClick={() => this.props.confirm(notification.id)}>X</div>
            </div>
        </ListGroup.Item>
    }
}

const mapStateToProps = (state: AppState): NotificationListProps => {
    return {
        data: state.data.dataset[EntityClass.NOTIFICATION] as NotificationEntity[]
    }
}

const mapDispatchToProps = (dispatch: Dispatch): NotificationListActions => {
    return {
        confirm: (id: number) => dispatch(notificationConfirmRequest(id))
    }
}

export const ConnectedNotificationList = connect(mapStateToProps, mapDispatchToProps)(NotificationList)

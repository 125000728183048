import React from 'react'

import './MenuBlock.css'
import { connect } from 'react-redux'
import { AppState, EntityClass } from '../../store/types'
import { ConnectedUserBlock } from '../user/UserBlock'
import { ClassNameProps } from '../types'
import { Dispatch } from 'redux'
import { selectEntityClass } from '../../store/action/select-entity-class'
import { logout } from '../../store/action/auth'

interface MenuBlockProps {
    entityClass: EntityClass

    notificationsNumber: number
}

interface MenuBlockAction {
    selectOrder: () => void
    selectOrderMaterial: () => void
    selectEmployeeApplication: () => void
    selectNotification: () => void
    logout: () => void
}

class MenuBlock extends React.Component<MenuBlockProps & MenuBlockAction, {}> {
    render () {
        return <div className={'mb-container'}>
            <div className={'mb-header'}>
                <h2 className={'mb-title'}>ООО &quot;КБУ&quot;</h2>
                <ConnectedUserBlock/>
            </div>
            <div className={'mb-root'}>
                <div
                    onClick={() => this.props.selectOrder()}
                    className={ this.props.entityClass === EntityClass.ORDER ? 'mb-item mb-selected' : 'mb-item'}
                >
                    Заказы
                </div>
                <div
                    onClick={() => this.props.selectOrderMaterial()}
                    className={ this.props.entityClass === EntityClass.ORDER_MATERIAL ? 'mb-item mb-selected' : 'mb-item'}
                >
                    Материалы
                </div>
                <div
                    onClick={() => this.props.selectEmployeeApplication()}
                    className={ this.props.entityClass === EntityClass.EMPLOYEE_APPLICATION ? 'mb-item mb-selected' : 'mb-item'}
                >
                    Заявки
                </div>
                <div
                    onClick={() => this.props.selectNotification()}
                    className={ this.props.entityClass === EntityClass.NOTIFICATION ? 'mb-item mb-selected' : 'mb-item'}
                >
                    <div className="mb-notification-item">
                        <div className="mb-notification-name">Уведомления</div>
                        <div className="mb-notification-counter">{this.props.notificationsNumber}</div>
                    </div>
                </div>
                <div className={'mb-spacer'}/>
                <div
                    onClick={() => this.props.logout()}
                    className={'mb-item'}
                >
                    Выйти
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: AppState): ClassNameProps & MenuBlockProps => {
    const workspace = state.workspace
    return {
        entityClass: workspace.currentEntityClass,
        notificationsNumber: state.data.dataset[EntityClass.NOTIFICATION].length
    }
}

const mapDispatchToProps = (dispatch: Dispatch): MenuBlockAction => {
    return {
        selectOrder: () => dispatch(selectEntityClass(EntityClass.ORDER)),
        selectOrderMaterial: () => dispatch(selectEntityClass(EntityClass.ORDER_MATERIAL)),
        selectEmployeeApplication: () => dispatch(selectEntityClass(EntityClass.EMPLOYEE_APPLICATION)),
        selectNotification: () => dispatch(selectEntityClass(EntityClass.NOTIFICATION)),
        logout: () => dispatch(logout())
    }
}

export const ConnectedMenu = connect(mapStateToProps, mapDispatchToProps)(MenuBlock)

import axios, { AxiosResponse, CancelToken } from 'axios'
import { EntityClass } from '../store/types'
import {
    CarEntity,
    EmployeeApplicationEntity,
    EmployeeApplicationStatus,
    Entity,
    EntityGroup,
    OrderEntity,
    OrderMaterialEntity,
    OrderMaterialStatus,
    OrderStatus
} from '../entity/types'
import { ApiResponse, ClientConfig, ErrorReason } from './types'
import { getAppNotifications } from './notification-api'
import moment from 'moment/moment'
import { defaultErrorHandler, entityModuleClient } from './client'

export interface GetEntityParams {
    offset?: number
    limit?: number
    filterCompleted?: boolean
}

function mapOrder (item: Record<string, any>): OrderEntity {
    return {
        id: item.id,
        date: moment.utc(item.date, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
        customer: item.customer,
        name: item.name,
        note: item.note,
        place: item.place,
        status: OrderStatus[item.status as keyof typeof OrderStatus],
        tc: item.tc,
        volume: item.volume
    }
}

function mapOrderGroups (data: any): EntityGroup<OrderEntity>[] {
    return (data as Array<Record<string, any>>)
        .map((item: Record<string, any>, index: number) => {
            return {
                id: index + 1,
                name: item.name,
                entities: (item.entities as Array<Record<string, any>>).map(mapOrder)
            }
        })
}

function mapOrderMaterials (data: any): OrderMaterialEntity[] {
    return (data as Array<Record<string, any>>).map((item: Record<string, any>): OrderMaterialEntity => {
        return {
            id: item.id,
            date: moment.utc(item.date, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
            concrete: item.concrete,
            master_air: item.master_air,
            master_glenim: item.master_glenim,
            master_pozzolith: item.master_pozzolith,
            status: OrderMaterialStatus[item.status as keyof typeof OrderMaterialStatus],
            note: item.note
        }
    })
}

function mapEmployeeApplications (data: any): EmployeeApplicationEntity[] {
    return (data as Array<Record<string, any>>).map((item: Record<string, any>): EmployeeApplicationEntity => {
        return {
            id: item.id,
            date: moment.utc(item.date, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
            name: item.name,
            count: item.count,
            price: item.price,
            target: item.target,
            status: EmployeeApplicationStatus[item.status as keyof typeof EmployeeApplicationStatus],
            note: item.note
        }
    })
}

function defaultResponseHandler<T> (dataMapper: (data: any) => T): (response: AxiosResponse) => ApiResponse<T> {
    return response => {
        const statusCode = response.status

        if (statusCode >= 500 && statusCode < 600) {
            return {
                errorReason: ErrorReason.SERVER_SIDE
            }
        }

        switch (statusCode) {
        case 200:
            return {
                data: dataMapper(response.data)
            }
        case 401:
            switch (response.data) {
            case 'token is expired':
                return {
                    errorReason: ErrorReason.TOKEN_EXPIRED
                }
            case 'signature is invalid':
                return {
                    errorReason: ErrorReason.INVALID_TOKEN
                }
            case 'unknown error':
            default:
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR
                }
            }
        default:
            return {
                errorReason: ErrorReason.UNKNOWN_ERROR
            }
        }
    }
}

function getOrders (config: ClientConfig, params: GetEntityParams, cancelToken?: CancelToken): Promise<ApiResponse<EntityGroup<OrderEntity>[]>> {
    return entityModuleClient
        .get('/v1/order', {
            baseURL: config.baseUrl,
            cancelToken: cancelToken,
            headers: {
                Token: config.token
            },
            params: {
                ...params,
                order: 'DESC',
                group: 'DATE_GROUPING'
            }
        })
        .then(defaultResponseHandler(mapOrderGroups))
}

function getOrderMaterials (config: ClientConfig, params: GetEntityParams, cancelToken?: CancelToken): Promise<ApiResponse<OrderMaterialEntity[]>> {
    return entityModuleClient
        .get('/v1/order-material', {
            baseURL: config.baseUrl,
            cancelToken: cancelToken,
            headers: {
                Token: config.token
            },
            params: {
                ...params,
                order: 'DESC'
            }
        })
        .then(defaultResponseHandler(mapOrderMaterials))
}

function getEmployeeApplications (config: ClientConfig, params: GetEntityParams, cancelToken?: CancelToken): Promise<ApiResponse<EmployeeApplicationEntity[]>> {
    return entityModuleClient
        .get('/v1/employee-application', {
            baseURL: config.baseUrl,
            cancelToken: cancelToken,
            headers: {
                Token: config.token
            },
            params: {
                ...params,
                order: 'DESC'
            }
        })
        .then(defaultResponseHandler(mapEmployeeApplications))
}

export function getCars (config: ClientConfig, params: GetEntityParams, cancelToken?: CancelToken): Promise<ApiResponse<CarEntity[]>> {
    return entityModuleClient
        .get('/v1/car', {
            baseURL: config.baseUrl,
            cancelToken: cancelToken,
            headers: {
                Token: config.token
            },
            params: {
                ...params,
                order: 'DESC'
            }
        })
        .then((response: AxiosResponse): ApiResponse<CarEntity[]> => {
            switch (response.status) {
            case 200:
                return {
                    data: (response.data as Array<Record<string, any>>).map((item: Record<string, any>): CarEntity => {
                        return {
                            id: item.id,
                            license: item.license
                        }
                    })
                }
            case 401:
                switch (response.data) {
                case 'token is expired':
                    return {
                        errorReason: ErrorReason.TOKEN_EXPIRED
                    }
                case 'signature is invalid':
                    return {
                        errorReason: ErrorReason.INVALID_TOKEN
                    }
                case 'unknown error':
                default:
                    return {
                        errorReason: ErrorReason.UNKNOWN_ERROR
                    }
                }
            default:
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR
                }
            }
        })
}

export function getEntity (config: ClientConfig, entityClass: EntityClass, params: GetEntityParams = {}): Promise<ApiResponse<Entity[]>> {
    let result: Promise<ApiResponse<Entity[]>>
    const cancelTokenSource = axios.CancelToken.source()
    const cancelToken = axios.CancelToken.source().token

    switch (entityClass) {
    case EntityClass.ORDER:
        result = getOrders(config, params, cancelToken)
        break
    case EntityClass.ORDER_MATERIAL:
        result = getOrderMaterials(config, params, cancelToken)
        break
    case EntityClass.EMPLOYEE_APPLICATION:
        result = getEmployeeApplications(config, params, cancelToken)
        break
    case EntityClass.NOTIFICATION:
        result = getAppNotifications(config)
        break
    }

    return result.catch(defaultErrorHandler(cancelTokenSource))
}

import { Dataset, EntityClass } from '../types'
import { AnyAction } from 'redux'
import { ActionTypes } from '../action/action'
import { Entity, EntityGroup, OrderEntity } from '../../entity/types'

const initState: Dataset = {
    dataset: {
        [EntityClass.ORDER]: [],
        [EntityClass.ORDER_MATERIAL]: [],
        [EntityClass.EMPLOYEE_APPLICATION]: [],
        [EntityClass.NOTIFICATION]: []
    }
}

export function dataReducer (state: Dataset = initState, action: AnyAction): Dataset {
    switch (action.type) {
    case ActionTypes.FETCH_DATASET_PAGE_SUCCEEDED:
        return {
            ...state,
            dataset: {
                ...state.dataset,
                [action.entityClass]: action.data
            }
        }
    case ActionTypes.CREATE_ENTITY_REQUEST_SUCCEEDED: {
        const entityClass = action.entityClass as EntityClass
        let newDataset: Entity[]

        if (entityClass === EntityClass.ORDER) {
            let newGroup = { ...state.dataset[entityClass][0] as EntityGroup<OrderEntity> }
            if (newGroup.name === 'New') {
                newGroup.entities.push(action.entity)
                newDataset = [newGroup, ...state.dataset[entityClass].slice(1)]
            } else {
                newGroup = {
                    id: 0,
                    name: 'New',
                    entities: [action.entity]
                }
                newDataset = [newGroup, ...state.dataset[entityClass]]
            }
        } else {
            newDataset = [action.entity as Entity, ...state.dataset[entityClass]]
        }

        return {
            ...state,
            dataset: {
                ...state.dataset,
                [entityClass]: newDataset
            }
        }
    }
    case ActionTypes.UPDATE_ENTITY_SUCCEEDED: {
        const entityClass = action.entityClass as EntityClass
        let newDataset: Entity[]

        if (entityClass === EntityClass.ORDER) {
            const updatedEntity = action.entity as OrderEntity
            const groups = [...state.dataset[entityClass]] as EntityGroup<OrderEntity>[]
            for (const group of groups) {
                const index = group.entities.findIndex((entity: Entity) => entity.id === updatedEntity.id)
                if (index > -1) {
                    group.entities[index] = updatedEntity
                    break
                }
            }
            newDataset = groups
        } else {
            const updatedEntity = action.entity as Entity
            newDataset = [...state.dataset[entityClass]]
            const index = newDataset.findIndex((entity: Entity) => entity.id === updatedEntity.id)
            newDataset[index] = updatedEntity
        }

        return {
            ...state,
            dataset: {
                ...state.dataset,
                [entityClass]: newDataset
            }
        }
    }
    case ActionTypes.DELETE_ENTITY_SUCCEEDED: {
        const entityClass = action.entityClass as EntityClass
        const entityId = action.entityId as number
        let newDataset: Entity[]

        if (entityClass === EntityClass.ORDER) {
            newDataset = [...state.dataset[entityClass]] as EntityGroup<OrderEntity>[]
            for (let i = 0; i < newDataset.length; i++) {
                const group = newDataset[i]
                const index = group.entities.findIndex((entity: Entity) => entity.id === entityId)
                if (index > -1) {
                    if (group.entities.length === 1) {
                        newDataset.splice(i, 1)
                    } else {
                        newDataset[i].entities.splice(index, 1)
                    }
                    break
                }
            }
        } else {
            newDataset = state.dataset[entityClass].filter((entity: Entity) => entity.id !== entityId)
        }

        return {
            ...state,
            dataset: {
                ...state.dataset,
                [entityClass]: newDataset
            }
        }
    }
    case ActionTypes.NOTIFICATION_CONFIRM_REQUEST_SUCCEEDED: {
        const entityId = action.id as number
        const newData = state.dataset[EntityClass.NOTIFICATION].filter((entity: Entity) => entity.id !== entityId)
        return {
            ...state,
            dataset: {
                ...state.dataset,
                [EntityClass.NOTIFICATION]: newData
            }
        }
    }
    case ActionTypes.AUTH_LOGOUT: {
        return { ...initState }
    }
    }

    return state
}

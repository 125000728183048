import React, { ReactNode } from 'react'

import { AppState } from './store/types'
import { ConnectedMenu } from './components/menu/MenuBlock'
import { ConnectedMain } from './components/main/Main'
import { ConnectedLogin } from './components/login/Login'
import { ToastContainer } from 'react-toastify'

import { connect } from 'react-redux'

import './App.css'
import 'react-toastify/dist/ReactToastify.css'

interface AppProps {
    isLogged: boolean
}

class App extends React.Component<AppProps, {}> {
    render (): ReactNode {
        return this.props.isLogged ? this.renderMainPage() : this.renderLoginPage()
    }

    renderLoginPage (): ReactNode {
        return <ConnectedLogin/>
    }

    renderMainPage (): ReactNode {
        return <>
            <div className={'app-container'}>
                <div className="split left">
                    <ConnectedMenu/>
                </div>
                <div className="split right">
                    <ConnectedMain/>
                </div>
            </div>
            <ToastContainer
                position={'top-right'}
                autoClose={1000 * 5}
            />
        </>
    }
}

const mapStateToProps = (state: AppState): AppProps => {
    return {
        isLogged: state.auth.isLogged
    }
}

export const ConnectedApp = connect(mapStateToProps)(App)

import { EntityClass } from '../store/types'
import { AxiosResponse } from 'axios'
import { Entity } from '../entity/types'
import { ApiResponse, ClientConfig, ErrorReason } from './types'
import { defaultErrorHandler, entityModuleClient } from './client'

function defaultResponseHandler (response: AxiosResponse): ApiResponse<boolean> {
    switch (response.status) {
    case 200:
    case 201:
        return {
            data: true
        }
    case 401:
        switch (response.data) {
        case 'token is expired':
            return {
                errorReason: ErrorReason.TOKEN_EXPIRED
            }
        case 'signature is invalid':
            return {
                errorReason: ErrorReason.INVALID_TOKEN
            }
        case 'unknown error':
        default:
            return {
                errorReason: ErrorReason.UNKNOWN_ERROR
            }
        }
    default:
        return {
            errorReason: ErrorReason.UNKNOWN_ERROR
        }
    }
}

function getPath (entityClass: EntityClass): string {
    switch (entityClass) {
    case EntityClass.ORDER:
        return '/v1/order'
    case EntityClass.ORDER_MATERIAL:
        return '/v1/order-material'
    case EntityClass.EMPLOYEE_APPLICATION:
        return '/v1/employee-application'
    default:
        throw new Error('unsupported entity class')
    }
}

export function deleteEntity (config: ClientConfig, entityClass: EntityClass, entityId: number): Promise<ApiResponse<boolean>> {
    return entityModuleClient
        .delete(getPath(entityClass), {
            baseURL: config.baseUrl,
            params: {
                id: entityId
            },
            headers: {
                Token: config.token
            }
        })
        .then(defaultResponseHandler)
        .catch(defaultErrorHandler())
}

export function updateEntity (config: ClientConfig, entityClass: EntityClass, entity: Entity): Promise<ApiResponse<boolean>> {
    return entityModuleClient
        .post(getPath(entityClass),
            { ...entity },
            {
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    Token: config.token
                }
            })
        .then(defaultResponseHandler)
        .catch(defaultErrorHandler())
}

export function createEntity (config: ClientConfig, entityClass: EntityClass, entity: Entity): Promise<ApiResponse<Entity>> {
    return entityModuleClient
        .put(
            getPath(entityClass),
            { ...entity, id: -1 },
            {
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    Token: config.token
                }
            }
        )
        .then((response: AxiosResponse): ApiResponse<Entity> => {
            switch (response.status) {
            case 200:
                return {
                    data: {
                        ...entity,
                        id: response.data.id
                    }
                }
            case 401:
                switch (response.data) {
                case 'token is expired':
                    return {
                        errorReason: ErrorReason.TOKEN_EXPIRED
                    }
                case 'signature is invalid':
                    return {
                        errorReason: ErrorReason.INVALID_TOKEN
                    }
                case 'unknown error':
                default:
                    return {
                        errorReason: ErrorReason.UNKNOWN_ERROR
                    }
                }
            default:
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR
                }
            }
        })
        .catch(defaultErrorHandler())
}

import axios, { CancelTokenSource } from 'axios'
import { ApiResponse, ErrorReason } from './types'

const timeoutErrorMessage = 'TIMEOUT'
const entityModuleClient = axios.create({
    timeout: 5000,
    timeoutErrorMessage: timeoutErrorMessage,
    validateStatus: () => true
})

export function defaultErrorHandler (cancelTokenSource?: CancelTokenSource): (reason: any) => ApiResponse<any> {
    return reason => {
        if (reason.code === 'ECONNABORTED' && reason.message === timeoutErrorMessage) {
            if (cancelTokenSource) {
                cancelTokenSource.cancel()
            }
            return {
                errorReason: ErrorReason.TIMEOUT
            }
        }
        return {
            errorReason: ErrorReason.UNKNOWN_ERROR
        }
    }
}

export { entityModuleClient }

import { ActionTypes } from './action'
import { EntityClass } from '../types'
import { Entity } from '../../entity/types'

export function updateEntity (entityClass: EntityClass, entity: Entity) {
    return {
        type: ActionTypes.UPDATE_ENTITY,
        entityClass: entityClass,
        entity: entity
    }
}

export function updateEntitySucceeded (entityClass: EntityClass, entity: Entity) {
    return {
        type: ActionTypes.UPDATE_ENTITY_SUCCEEDED,
        entityClass: entityClass,
        entity: entity
    }
}

export function updateEntityFailed () {
    return {
        type: ActionTypes.UPDATE_ENTITY_FAILED
    }
}

export function createEntity (entityClass: EntityClass) {
    return {
        type: ActionTypes.CREATE_ENTITY,
        entityClass: entityClass
    }
}

export function createEntityRequest (entityClass: EntityClass, entity: Entity) {
    return {
        type: ActionTypes.CREATE_ENTITY_REQUEST,
        entityClass: entityClass,
        entity: entity
    }
}

export function createEntityRequestSucceeded (entityClass: EntityClass, entity: Entity) {
    return {
        type: ActionTypes.CREATE_ENTITY_REQUEST_SUCCEEDED,
        entityClass: entityClass,
        entity: entity
    }
}

export function createEntityRequestFailed () {
    return {
        type: ActionTypes.CREATE_ENTITY_REQUEST_FAILED
    }
}

export function deleteEntity (entityClass: EntityClass, entityId: number) {
    return {
        type: ActionTypes.DELETE_ENTITY,
        entityClass: entityClass,
        entityId: entityId
    }
}

export function deleteEntitySucceeded (entityClass: EntityClass, entityId: number) {
    return {
        type: ActionTypes.DELETE_ENTITY_SUCCEEDED,
        entityClass: entityClass,
        entityId: entityId
    }
}

export function deleteEntityFailed () {
    return {
        type: ActionTypes.DELETE_ENTITY_FAILED
    }
}

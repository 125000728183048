import { AnyAction } from 'redux'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../action/action'
import { AuthResponseData, login } from '../../api/auth-api'
import { loginRequestFailed, loginRequestSucceeded } from '../action/auth'
import { ApiResponse, ErrorReason } from '../../api/types'
import { AppState, ListWorkspace, TableEditorWorkspace } from '../types'
import { fetchDatasetPage } from '../action/fetch-data'
import { Entity } from '../../entity/types'
import { GetEntityParams } from '../../api/get-entity'

const projectId = '151f3308-18ed-4d45-966c-94ea8665e557' // TODO configure via env

export const workspaceDatasetSelector = (state: AppState) => [state.workspace.workspaces[state.workspace.currentEntityClass], state.data.dataset[state.workspace.currentEntityClass]]

export function * loginRequestSaga (action: AnyAction) {
    const response: ApiResponse<AuthResponseData> = yield call(login, action.clientConfig, {
        username: action.username,
        password: action.password,
        project: projectId
    })
    if (!response.errorReason && response.data) {
    // todo add a checks and the payload type
        const data = response.data
        yield put(loginRequestSucceeded(data.token, data.expiredTime, data.userInfo))

        // update current dataset
        const [workspace, entities]: [ListWorkspace | TableEditorWorkspace, Entity[]] = yield select(workspaceDatasetSelector)
        if (entities.length === 0) {
            // todo refactor. move to separate method
            const params: GetEntityParams = {}
            if ('dataFilter' in workspace) {
                params.filterCompleted = workspace.dataFilter.filterCompletedRecords
            }
            yield put(fetchDatasetPage(workspace.entityClass, params))
        }
    } else {
        yield put(loginRequestFailed(response.errorReason || ErrorReason.UNKNOWN_ERROR))
    }
}

export function * loginSagas () {
    yield takeEvery(ActionTypes.AUTH_LOGIN_REQUEST, loginRequestSaga)
}

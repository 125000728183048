import { ActionTypes } from './action'

export function workspaceStartLoad () {
    return {
        type: ActionTypes.WORKSPACE_START_LOAD
    }
}

export function workspaceFinishLoad () {
    return {
        type: ActionTypes.WORKSPACE_FINISH_LOAD
    }
}


export enum ActionTypes {
    WORKSPACE_SELECT_ENTITY_CLASS = 'WORKSPACE.SELECT_ENTITY_CLASS',
    WORKSPACE_PRE_SELECT_ENTITY_CLASS = 'WORKSPACE.PRE_SELECT_ENTITY_CLASS',

    SELECT_ENTITY = 'SELECT_ENTITY',
    UNSELECT_ENTITY = 'UNSELECT_ENTITY',

    WORKSPACE_CHANGE_DATA_FILTER = 'WORKSPACE.CHANGE_DATA_FILTER',
    WORKSPACE_PRE_CHANGE_DATA_FILTER = 'WORKSPACE.PRE_CHANGE_DATA_FILTER',

    WORKSPACE_START_LOAD = 'WORKSPACE.START_LOAD',
    WORKSPACE_FINISH_LOAD = 'WORKSPACE.FINISH_LOAD',

    FETCH_DATASET_PAGE = 'FETCH_DATASET_PAGE',
    FETCH_DATASET_PAGE_SUCCEEDED = 'FETCH_DATASET_PAGE_SUCCEEDED',
    FETCH_DATASET_PAGE_FAILED = 'FETCH_DATASET_PAGE_FAILED',

    CREATE_ENTITY = 'CREATE_ENTITY',
    CREATE_ENTITY_REQUEST = 'CREATE_ENTITY.REQUEST',
    CREATE_ENTITY_REQUEST_SUCCEEDED = 'CREATE_ENTITY.REQUEST_SUCCEEDED',
    CREATE_ENTITY_REQUEST_FAILED = 'CREATE_ENTITY.REQUEST_FAILED',

    UPDATE_ENTITY = 'UPDATE_ENTITY',
    UPDATE_ENTITY_SUCCEEDED = 'UPDATE_ENTITY_SUCCEEDED',
    UPDATE_ENTITY_FAILED = 'UPDATE_ENTITY_FAILED',

    DELETE_ENTITY = 'DELETE_ENTITY',
    DELETE_ENTITY_SUCCEEDED = 'DELETE_ENTITY_SUCCEEDED',
    DELETE_ENTITY_FAILED = 'DELETE_ENTITY_FAILED',

    AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST',
    AUTH_LOGIN_REQUEST_SUCCEEDED = 'AUTH_LOGIN_REQUEST_SUCCEEDED',
    AUTH_LOGIN_REQUEST_FAILED = 'AUTH_LOGIN_REQUEST_FAILED',
    AUTH_LOGOUT = 'AUTH_LOGOUT',
    AUTH_TOKEN_EXPIRED = 'AUTH_TOKEN_EXPIRED',

    NOTIFICATION_CONFIRM_REQUEST = 'NOTIFICATION_CONFIRM.REQUEST',
    NOTIFICATION_CONFIRM_REQUEST_SUCCEEDED = 'NOTIFICATION_CONFIRM.REQUEST_SUCCEEDED',
    NOTIFICATION_CONFIRM_REQUEST_FAILED = 'NOTIFICATION_CONFIRM.REQUEST_FAILED',
}

import { applyMiddleware, combineReducers, createStore } from 'redux'
import { workspaceReducer } from './reduser/workspace'
import { composeWithDevTools } from 'redux-devtools-extension'
import { watchFetchDataset } from './saga/fetch-dataset'
import createSagaMiddleware from 'redux-saga'
import { writeEntitySagas } from './saga/write-entity'
import { loginSagas } from './saga/login'
import { apiRequestMiddleware } from './middleware/api'
import { createTransform, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import { authReducer } from './reduser/auth'
import { dataReducer } from './reduser/data'
import { notificationSagas } from './saga/notification'
import { authTokenExpired } from './action/auth'
import { fetchDatasetPage } from './action/fetch-data'
import { GetEntityParams } from '../api/get-entity'
import { EntityClass, EntityInfo, TableEditorWorkspace } from './types'
import moment from 'moment/moment'

const sagaMiddleware = createSagaMiddleware()

function transformSelectedEntity (entity?: EntityInfo): EntityInfo | undefined {
    if (!!entity && !!entity.entity.date) {
        return {
            ...entity,
            entity: {
                ...entity.entity,
                date: moment.utc(entity.entity.date, 'YYYY-MM-DDThh:mm:ss.SSSZ')
            }
        }
    }
    return undefined
}

const authPersistConfig = {
    key: 'auth',
    storage: storage // todo consider to use cookie for auth.token
}
const workspacePersistConfig = {
    key: 'workspace',
    storage: storage,
    // todo refactor, add tests
    transforms: [createTransform(
        (state: any) => state,
        (outboundState: any) => {
            return {
                ...outboundState,
                [EntityClass.ORDER]: {
                    ...outboundState[EntityClass.ORDER],
                    selectedEntity: transformSelectedEntity((outboundState[EntityClass.ORDER] as TableEditorWorkspace).selectedEntity)
                },
                [EntityClass.ORDER_MATERIAL]: {
                    ...outboundState[EntityClass.ORDER_MATERIAL],
                    selectedEntity: transformSelectedEntity((outboundState[EntityClass.ORDER_MATERIAL] as TableEditorWorkspace).selectedEntity)
                },
                [EntityClass.EMPLOYEE_APPLICATION]: {
                    ...outboundState[EntityClass.EMPLOYEE_APPLICATION],
                    selectedEntity: transformSelectedEntity((outboundState[EntityClass.EMPLOYEE_APPLICATION] as TableEditorWorkspace).selectedEntity)
                }
            }
        },
        { whitelist: ['workspaces'] }
    )
    ]
}

const reducers = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    workspace: persistReducer(workspacePersistConfig, workspaceReducer),
    data: dataReducer
})

const store = createStore(reducers, composeWithDevTools(applyMiddleware(apiRequestMiddleware, sagaMiddleware)))
const persistor = persistStore(store, null, () => {
    const state = store.getState()

    if (state.auth.expiredTime - Date.now() / 1000 < 0) {
        store.dispatch(authTokenExpired())
    } else {
        const currentWorkspace = state.workspace
        const currentEntityClass = currentWorkspace.currentEntityClass
        if (state.data.dataset[currentEntityClass].length === 0) {
            const params: GetEntityParams = {}
            const workspace = currentWorkspace.workspaces[currentEntityClass]
            if ('dataFilter' in workspace) {
                params.filterCompleted = workspace.dataFilter.filterCompletedRecords
            }
            store.dispatch(fetchDatasetPage(currentEntityClass, params))
        }
    }
})

sagaMiddleware.run(watchFetchDataset)
sagaMiddleware.run(writeEntitySagas)
sagaMiddleware.run(loginSagas)
sagaMiddleware.run(notificationSagas)

export {
    store, persistor
}

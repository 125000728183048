import { AnyAction } from 'redux'
import { call, put, takeEvery } from 'redux-saga/effects'
import { EntityClass } from '../types'
import { ActionTypes } from '../action/action'
import { createEntity, deleteEntity, updateEntity } from '../../api/entity-write-api'
import {
    createEntityRequestFailed,
    createEntityRequestSucceeded,
    deleteEntityFailed,
    deleteEntitySucceeded,
    updateEntityFailed,
    updateEntitySucceeded
} from '../action/entity-actions'
import { Entity } from '../../entity/types'
import { ApiResponse, ErrorReason } from '../../api/types'
import { authTokenExpired } from '../action/auth'
import { selectEntity } from '../action/select-entity'
import { toast } from 'react-toastify'

export function * deleteEntitySaga (action: AnyAction) {
    const response: ApiResponse<boolean> = yield call(deleteEntity, action.clientConfig, action.entityClass as EntityClass, action.entityId)
    if (!response.errorReason && response.data) {
        yield put(deleteEntitySucceeded(action.entityClass as EntityClass, action.entityId as number))
    } else {
        switch (response.errorReason) {
        case ErrorReason.TOKEN_EXPIRED:
            yield put(authTokenExpired())
            break
        default:
            toast.warn('Не удалось удалить запись. Попробуйте позже или свяжитесь с администратором.')
            yield put(deleteEntityFailed())
            break
        }
    }
}

export function * updateEntitySaga (action: AnyAction) {
    const entity = action.entity as Entity
    const entityClass = action.entityClass as EntityClass
    const response: ApiResponse<boolean> = yield call(updateEntity, action.clientConfig, entityClass, entity)
    if (!response.errorReason && response.data) {
        yield put(updateEntitySucceeded(entityClass, entity))
    } else {
        switch (response.errorReason) {
        case ErrorReason.TOKEN_EXPIRED:
            yield put(authTokenExpired())
            break
        default:
            toast.warn('Не удалось обновить запись. Попробуйте позже или свяжитесь с администратором.')
            yield put(updateEntityFailed())
            break
        }
    }
}

export function * createEntitySaga (action: AnyAction) {
    const entity = action.entity as Entity
    const entityClass = action.entityClass as EntityClass
    const response: ApiResponse<Entity> = yield call(createEntity, action.clientConfig, entityClass, entity)
    if (!response.errorReason && response.data) {
        yield put(createEntityRequestSucceeded(entityClass, response.data))
        yield put(selectEntity(response.data))
    } else {
        switch (response.errorReason) {
        case ErrorReason.TOKEN_EXPIRED:
            yield put(authTokenExpired())
            break
        default:
            toast.warn('Не удалось создать запись. Попробуйте позже или свяжитесь с администратором.')
            yield put(createEntityRequestFailed())
            break
        }
    }
}

export function * writeEntitySagas () {
    yield takeEvery(ActionTypes.CREATE_ENTITY_REQUEST, createEntitySaga)
    yield takeEvery(ActionTypes.UPDATE_ENTITY, updateEntitySaga)
    yield takeEvery(ActionTypes.DELETE_ENTITY, deleteEntitySaga)
}

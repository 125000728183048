import { ActionTypes } from '../action/action'
import { call, put, putResolve, select, takeEvery } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { fetchDatasetPage, fetchDatasetPageFailed, fetchDatasetPageSucceeded } from '../action/fetch-data'
import { AppState, EntityClass, ListWorkspace, TableEditorWorkspace } from '../types'
import { getEntity, GetEntityParams } from '../../api/get-entity'
import { ApiResponse, ErrorReason } from '../../api/types'
import { Entity } from '../../entity/types'
import { authTokenExpired } from '../action/auth'
import { workspaceDatasetSelector } from './login'
import { workspaceFinishLoad, workspaceStartLoad } from '../action/workspace'
import { toast } from 'react-toastify'

export function * fetchDataset (action: AnyAction) {
    yield put(workspaceStartLoad())

    let response: ApiResponse<Entity[]> = {}
    for (let attempt = 0; attempt < 3; attempt++) {
        response = yield call(getEntity, action.clientConfig, action.entityClass as EntityClass, action.params)

        if (!response.errorReason && response.data) {
            break
        }
        if (response.errorReason !== ErrorReason.TIMEOUT && response.errorReason !== ErrorReason.SERVER_SIDE) {
            break
        }
    }

    if (!response.errorReason && response.data) {
        yield put(fetchDatasetPageSucceeded(action.entityClass as EntityClass, response.data))
    } else {
        switch (response.errorReason) {
        case ErrorReason.TOKEN_EXPIRED:
            yield put(authTokenExpired())
            break
        default:
            toast.warn('Не удалось загрузить данные. Попробуйте позже или свяжитесь с администратором.')
            yield put(fetchDatasetPageFailed())
            break
        }
    }

    yield put(workspaceFinishLoad())
}

export function * refreshDataOnChangeDataFilter (action: AnyAction) {
    action.type = ActionTypes.WORKSPACE_CHANGE_DATA_FILTER
    yield putResolve(action)

    const workspace: ListWorkspace | TableEditorWorkspace = yield select((state: AppState) => state.workspace.workspaces[state.workspace.currentEntityClass])
    const params: GetEntityParams = {}
    if ('dataFilter' in workspace) {
        params.filterCompleted = workspace.dataFilter.filterCompletedRecords
    }
    yield put(fetchDatasetPage(workspace.entityClass, params))
}

export function * refreshDataOnSelectEntityClass (action: AnyAction) {
    action.type = ActionTypes.WORKSPACE_SELECT_ENTITY_CLASS
    yield putResolve(action)

    const [workspace, data]: [ListWorkspace | TableEditorWorkspace, Entity[]] = yield select(workspaceDatasetSelector)
    if (data.length === 0) {
        const params: GetEntityParams = {}
        if ('dataFilter' in workspace) {
            params.filterCompleted = workspace.dataFilter.filterCompletedRecords
        }
        yield put(fetchDatasetPage(workspace.entityClass, params))
    }
}

export function * watchFetchDataset () {
    yield takeEvery(ActionTypes.FETCH_DATASET_PAGE, fetchDataset)
    yield takeEvery(ActionTypes.WORKSPACE_PRE_CHANGE_DATA_FILTER, refreshDataOnChangeDataFilter)
    yield takeEvery(ActionTypes.WORKSPACE_PRE_SELECT_ENTITY_CLASS, refreshDataOnSelectEntityClass)
}

import { ApiResponse, ClientConfig, ErrorReason } from './types'
import { NotificationEntity, NotificationSeverity } from '../entity/types'
import axios, { AxiosResponse } from 'axios'
import moment from 'moment/moment'

export function getAppNotifications (config: ClientConfig): Promise<ApiResponse<NotificationEntity[]>> {
    return axios
        .get('/notification/v1/notification', {
            baseURL: config.baseUrl,
            timeout: 5000,
            params: {
                user_id: config.userId
            },
            validateStatus: () => true
        })
        .then((response: AxiosResponse): ApiResponse<NotificationEntity[]> => {
            switch (response.status) {
            case 200:
                return {
                    data: (response.data as Array<Record<string, any>>).map((item: Record<string, any>): NotificationEntity => {
                        return {
                            id: item.id,
                            date: moment.utc(item.date, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
                            severity: NotificationSeverity[item.severity as keyof typeof NotificationSeverity],
                            text: item.text
                        }
                    })
                }
            default:
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR
                }
            }
        })
}

export function confirmAppNotification (config: ClientConfig, notificationId: number): Promise<ApiResponse<boolean>> {
    return axios
        .post('/notification/v1/notification', '', {
            baseURL: config.baseUrl,
            timeout: 5000,
            params: {
                notificationId: notificationId
            },
            validateStatus: () => true
        })
        .then((response: AxiosResponse): ApiResponse<boolean> => {
            switch (response.status) {
            case 200:
                return {
                    data: true
                }
            default:
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR
                }
            }
        })
}

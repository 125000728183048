import React from 'react'
import { ConnectedControlBlock } from '../control/ControlBlock'
import { ConnectedTableBlock } from '../table/TableBlock'
import { ConnectedEditor } from '../editor/Editor'
import { AppState, EntityClass } from '../../store/types'

import './Main.css'
import {
    OrderEditorConfig,
    ApplicationEditorConfig,
    ApplicationSchema,
    ApplicationTableSchema,
    MaterialEditorConfig,
    MaterialSchema,
    MaterialTableSchema,
    OrderSchema,
    OrderTableSchema
} from '../../entity/schema'
import { connect } from 'react-redux'
import { ConnectedNotificationList } from '../notification-list/NotificationList'
import { LoadingOverlay } from '../loading-overlay/LoadingOverlay'
import { ConnectedGroupedTable } from '../table/GroupedTable'

interface MainProps {
    entityClass: EntityClass
    loading: boolean
}

export class Main extends React.Component<MainProps, {}> {
    render () {
        switch (this.props.entityClass) {
        case EntityClass.ORDER:
            return <>
                {this.props.loading ? <LoadingOverlay/> : ''}
                <div className="mainb-hsplit mainb-table-container">
                    <div className={'ws-sticky-cp'}>
                        <ConnectedControlBlock/>
                    </div>
                    <div className={'ws-table'}>
                        <ConnectedGroupedTable spec={{
                            groupSpec: {
                                format: (value: string) => {
                                    switch (value) {
                                    case 'New':
                                        return 'Новые'
                                    case 'Today':
                                        return 'Сегодня'
                                    case 'Tomorrow':
                                        return 'Завтра'
                                    case 'OnThisWeek':
                                        return 'На этой неделе'
                                    case 'Later':
                                        return 'Позже'
                                    case 'Earlier':
                                        return 'Раньше'
                                    case 'Yesterday':
                                        return 'Вчера'
                                    default:
                                        return value
                                    }
                                }
                            },
                            columnSpec: OrderTableSchema
                        }}/>
                    </div>
                </div>
                <ConnectedEditor className="mainb-hsplit" editorLayout={OrderEditorConfig} schema={OrderSchema}/>
            </>
        case EntityClass.ORDER_MATERIAL:
            return <>
                {this.props.loading ? <LoadingOverlay/> : ''}
                <div className="mainb-hsplit mainb-table-container">
                    <div className={'ws-sticky-cp'}>
                        <ConnectedControlBlock/>
                    </div>
                    <div className={'ws-table'}>
                        <ConnectedTableBlock spec={MaterialTableSchema}/>
                    </div>
                </div>
                <ConnectedEditor className="mainb-hsplit" editorLayout={MaterialEditorConfig} schema={MaterialSchema}/>
            </>
        case EntityClass.EMPLOYEE_APPLICATION:
            return <>
                {this.props.loading ? <LoadingOverlay/> : ''}
                <div className="mainb-hsplit mainb-table-container">
                    <div className={'ws-sticky-cp'}>
                        <ConnectedControlBlock/>
                    </div>
                    <div className={'ws-table'}>
                        <ConnectedTableBlock spec={ApplicationTableSchema}/>
                    </div>
                </div>
                <ConnectedEditor className="mainb-hsplit" editorLayout={ApplicationEditorConfig} schema={ApplicationSchema}/>
            </>
        case EntityClass.NOTIFICATION:
            return <ConnectedNotificationList/>
        }
    }
}

const mapStateToProps = (state: AppState): MainProps => {
    return {
        entityClass: state.workspace.currentEntityClass,
        loading: state.workspace.loading
    }
}

export const ConnectedMain = connect(mapStateToProps)(Main)
